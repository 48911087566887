import { CloudArrowUpIcon, TrashIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { FileUpload } from '@/components/Form/FileUpload';

interface Props extends Omit<React.ComponentProps<typeof FileUpload>, 'value' | 'onChange'> {
  value?: string | File | null;
  invalid?: boolean;
  onChange(value: File | null): void;
}

export const TicketImageUpload: React.FC<Props> = ({ value, invalid, className, children, onChange, ...props }) => {
  const [preview, setPreview] = React.useState<string>();

  React.useEffect(() => {
    if (!(value instanceof File)) return setPreview(value || undefined);

    const reader = new FileReader();

    reader.onload = () => setPreview(reader.result as string);
    reader.onerror = () => {
      setPreview(undefined);
      onChange(null);
    };

    reader.readAsDataURL(value);
  }, [value, onChange]);

  return (
    <div className={classnames('relative', className)}>
      <FileUpload
        {...props}
        accept="image/jpeg, image/png, application/pdf"
        className={classnames(
          'group relative flex aspect-video cursor-pointer items-center justify-center overflow-hidden rounded bg-[#F3F0E8] hover:bg-opacity-80',
          !!invalid && 'ring-1 ring-red-500'
        )}
        onChange={(files) => onChange(files[0])}
      >
        <div className="absolute inset-0 flex items-center justify-center font-medium">
          {preview ? (
            <React.Fragment>
              <img src={preview} className="h-full w-full object-contain" />

              <button
                type="button"
                className="absolute left-1/2 top-1/2 hidden -translate-x-1/2 -translate-y-1/2 items-center rounded bg-white p-2 group-hover:inline-flex"
                onClick={() => onChange(null)}
              >
                <TrashIcon className="mr-2 h-5 w-5" aria-hidden="true" />

                <FormattedMessage id={translations.buttons.remove} />
              </button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <CloudArrowUpIcon className="mr-2 h-5 w-5" />

              {children}
            </React.Fragment>
          )}
        </div>
      </FileUpload>
    </div>
  );
};
