import { PurchaseLocation } from '@repay/api-sdk';
import React from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

import { ProgressManager } from '@/hooks/useProgressManager';
import { translations } from '@/locales';

import { Form } from '@/components/Form';
import { RadioSelect } from '@/components/Form/RadioSelect';
import { Step, StepHeader } from '@/components/Step';

import other from '@/assets/icons/ticket.svg';
import online from './online.svg';
import station from './station.svg';

const purchaseLocations = [
  { src: station, value: PurchaseLocation.STATION },
  { src: online, value: PurchaseLocation.ONLINE },
  { src: other, value: PurchaseLocation.OTHER }
];

const schema = yup
  .object({ purchaseLocation: yup.mixed<PurchaseLocation>().oneOf(Object.values(PurchaseLocation)).required() })
  .required();

export const PurchaseLocationStep: React.FC = () => {
  const { state, updatePurchaseLocation } = ProgressManager.useContainer();

  const onSubmit = React.useCallback(
    ({ purchaseLocation }: yup.InferType<typeof schema>) => updatePurchaseLocation(purchaseLocation),
    [updatePurchaseLocation]
  );

  return (
    <Step>
      <StepHeader title={<FormattedMessage id={translations.pages.progress.purchaseLocation.title} />} />

      <Form {...{ initialValues: { purchaseLocation: state.purchaseLocation }, schema, onSubmit }}>
        {({ handleSubmit }) => (
          <React.Fragment>
            <div className="max-w-[320px] space-y-4">
              {purchaseLocations.map(({ src, value }) => (
                <Field
                  key={value}
                  id={`purchase-location-${value}`}
                  name="purchaseLocation"
                  type="radio"
                  {...{ value }}
                >
                  {({ input, meta, ...props }) => (
                    <RadioSelect
                      {...props}
                      {...input}
                      contentClassName="flex items-center"
                      onClick={() => setTimeout(handleSubmit, 0)}
                    >
                      <img {...{ src }} className="mr-4 h-6 w-6" />
                      <FormattedMessage id={translations.enum.purchaseLocation[value]} />
                    </RadioSelect>
                  )}
                </Field>
              ))}
            </div>
          </React.Fragment>
        )}
      </Form>
    </Step>
  );
};
