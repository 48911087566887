import React from 'react';
import { useField } from 'react-final-form';

import { AsComponent, AsProps } from '@/components/As';
import { ValidationMessage } from '@/components/Form/ValidationMessage';

const usePropagatedHandler = <T extends React.FocusEvent<HTMLElement>, R>(
  fieldCallback: (event: T) => R,
  propCallback?: (event: T) => R
) => {
  return React.useCallback(
    (event: T) => {
      if (!propCallback) return fieldCallback(event);

      const result = propCallback(event) as any;

      return fieldCallback(result ?? event);
    },
    [fieldCallback, propCallback]
  );
};

type Props<F extends AsComponent, T extends AsComponent> = Omit<AsProps<F>, 'as' | 'value' | 'onChange'> &
  Omit<AsProps<T>, 'as' | 'value' | 'onChange'> & {
    field: AsProps<F>['as'];
    as?: AsProps<T>['as'];
    parse?(value: any, name: string): any;
    format?(value: any, name: string): any;
    allowNull?: boolean;
    fieldClassName?: string;
  };

export function ValidatedField<F extends AsComponent, T extends AsComponent>({
  field: Field,
  name,
  type,
  validated,
  parse,
  format,
  allowNull,
  className,
  fieldClassName,
  ...props
}: React.PropsWithChildren<Props<F, T>>) {
  const { meta, input } = useField(name, { type, parse, format, allowNull });

  const error = React.useMemo(() => {
    const invalid = meta.submitError ? !!meta.submitError && !meta.dirtySinceLastSubmit : meta.invalid;

    if (!invalid) return undefined;

    const visible = !!meta.touched && invalid; // !meta.active &&
    const id = meta.error?.id || meta.submitError;
    const values = meta.error?.values;

    return { visible, id, values };
  }, [meta]);

  const onFocus = usePropagatedHandler(input.onFocus, props.onFocus);
  const onBlur = usePropagatedHandler(input.onBlur, props.onBlur);
  const onChange = usePropagatedHandler(input.onChange, props.onChange);

  return (
    <div {...{ className }}>
      <Field
        {...props}
        {...input}
        invalid={!!error?.visible}
        {...{ type, onFocus, onBlur, onChange }}
        className={fieldClassName}
      />
      {!!error && <ValidationMessage {...error} visible={!!error?.visible} />}
    </div>
  );
}
