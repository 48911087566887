import classnames from 'classnames';
import React from 'react';

import { As, AsComponent, AsProps } from '@/components/As';

export type Props<T extends AsComponent> = AsProps<T> & {
  invalid?: boolean;
};

export const getInputReadOnlyClassNames = (readOnly?: boolean) => classnames(readOnly && 'bg-[#F3F0E8]');
export const getInputInvalidClassNames = (invalid?: boolean) =>
  classnames(invalid ? 'border-red-500' : 'border-[#003F2E4D]');

export const Input = React.forwardRef(function Input<T extends AsComponent = 'input'>(
  // @ts-ignore
  { as = 'input', invalid, ...props }: React.PropsWithChildren<Props<T>>,
  ref: React.Ref<any>
) {
  return (
    // @ts-ignore
    <As
      {...{ as, ref }}
      {...props}
      className={classnames(
        'block h-12 w-full rounded border border-solid text-sm text-gray-900',
        getInputInvalidClassNames(props.invalid),
        getInputReadOnlyClassNames(props.readOnly || props.disabled),
        props.className
      )}
    />
  );
});
