import { translations } from '@/locales';
import { Customer } from '@repay/api-sdk';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

import { BackButton } from '@/components/BackButton';
import { Form, Props as FormProps } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { StepFooter } from '@/components/Step';
import { SubmitButton } from '@/components/SubmitButton';

const schema = yup
  .object({
    email: yup.string().email().required().label(translations.fields.emailAddress)
  })
  .required() as yup.SchemaOf<{ email?: string }>;

interface Props extends Partial<FormProps<typeof schema>> {
  customer?: Pick<Customer, 'email'>;
  onBack?(): void;
  onSubmit(userIdentifier: { email: string }): Promise<void>;
}

export const LoginForm: React.FC<Props> = ({ customer, onBack, onSubmit, ...rest }) => (
  <Form {...rest} {...{ initialValues: { ...customer }, schema, onSubmit }}>
    {({ submitting }) => (
      <React.Fragment>
        <ValidatedField
          field={InputWithLabel}
          id="email"
          name="email"
          type="text"
          label={<FormattedMessage id={translations.fields.emailAddress} />}
          disabled={submitting}
        />

        <StepFooter>
          <SubmitButton loading={submitting}>
            <FormattedMessage id={translations.buttons.continue} />
          </SubmitButton>

          {!!onBack && <BackButton onClick={onBack} />}
        </StepFooter>
      </React.Fragment>
    )}
  </Form>
);
