import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

import { translations } from '@/locales';

import { Alert } from '@/components/Alert';
import { BackButton } from '@/components/BackButton';
import { Form } from '@/components/Form';
import { OtpInput } from '@/components/Form/OtpInput';
import { SubmitAutomaticallyWhenValid } from '@/components/Form/SubmitAutomaticallyWhenValid';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { StepFooter } from '@/components/Step';
import { SubmitButton } from '@/components/SubmitButton';

interface Props {
  onBack?(): void;
  onSubmit(code: string): Promise<void>;
  className?: string;
}

export const OtpForm: React.FC<Props> = ({ onBack, onSubmit, className }) => {
  const length = 6;

  const schema = React.useMemo(
    () =>
      yup
        .object({ code: yup.string().length(length).required().label(translations.fields.verificationCode) })
        .required(),
    [length]
  );

  return (
    <Form {...{ schema, className }} onSubmit={({ code }) => onSubmit(code)}>
      {({ submitting, hasValidationErrors, submitError }) => (
        <React.Fragment>
          <SubmitAutomaticallyWhenValid />

          <div className="space-y-4">
            <Alert type="info" title="Test instructions:">
              <div>
                OTP: <span className="font-medium">111111</span>
              </div>
            </Alert>

            <ValidatedField field={OtpInput} id="code" name="code" type="text" {...{ length }} disabled={submitting} />
          </div>

          <StepFooter>
            <SubmitButton loading={!!submitting} disabled={!!submitting || hasValidationErrors}>
              <FormattedMessage id={translations.buttons.continue} />
            </SubmitButton>

            {!!onBack && <BackButton onClick={onBack} />}
          </StepFooter>

          {!!submitError && (
            <Alert className="mt-4" type="error" title={submitError?.body?.message ?? submitError?.message} />
          )}
        </React.Fragment>
      )}
    </Form>
  );
};
