import React from 'react';

import { ProgressManager, ProgressStep } from '@/hooks/useProgressManager';

import { Notification } from '@/components/Notification';
import { useNotification } from '@/hooks/useNotification';

import { Navigation } from './Navigation';
import { CanceledStep } from './steps/CanceledStep';
import { ContactDetailsStep } from './steps/ContactDetailsStep';
import { TickedAddedNotification } from './steps/notifications/TickedAddedNotification';
import { BankDetailsStep } from './steps/PaymentDetails/BankDetailsStep';
import { CardDetailsStep } from './steps/PaymentDetails/CardDetailsStep';
import { WarrantDetailsStep } from './steps/PaymentDetails/WarrantDetailsStep';
import { RefundReasonStep } from './steps/RefundReasonStep';
import { RefundSummaryStep } from './steps/RefundSummaryStep';
import { AgreementStep } from './steps/TicketDetails/AgreementStep';
import { OtherTicketStep } from './steps/TicketDetails/OtherTicketStep';
import { PurchaseLocationStep } from './steps/TicketDetails/PurchaseLocationStep';
import { SearchTicketsByBookingStep } from './steps/TicketDetails/SearchTicketsByBookingStep';
import { SearchTicketsBySmartcardStep } from './steps/TicketDetails/SearchTicketsBySmartcardStep';
import { TicketDetailsStep } from './steps/TicketDetails/TicketDetailsStep';
import { TicketFormatStep } from './steps/TicketDetails/TicketFormatStep';
import { TicketImageStep } from './steps/TicketDetails/TicketImages/TicketImageStep';
import { TicketSelectionStep } from './steps/TicketDetails/TicketSelectionStep';
import { TicketTypeStep } from './steps/TicketDetails/TicketTypeStep';

const map: Record<ProgressStep, React.FC> = {
  [ProgressStep.Agreement]: AgreementStep,
  [ProgressStep.PurchaseLocation]: PurchaseLocationStep,
  [ProgressStep.OtherTicket]: OtherTicketStep,
  [ProgressStep.TicketFormat]: TicketFormatStep,
  [ProgressStep.SearchTicketsByBooking]: SearchTicketsByBookingStep,
  [ProgressStep.SearchTicketsBySmartcard]: SearchTicketsBySmartcardStep,
  [ProgressStep.TicketType]: TicketTypeStep,
  [ProgressStep.TicketDetails]: TicketDetailsStep,
  [ProgressStep.TicketSelection]: TicketSelectionStep,
  [ProgressStep.RefundReason]: RefundReasonStep,
  [ProgressStep.TicketImages]: TicketImageStep,
  [ProgressStep.ContactDetails]: ContactDetailsStep,
  [ProgressStep.CardDetails]: CardDetailsStep,
  [ProgressStep.WarrantDetails]: WarrantDetailsStep,
  [ProgressStep.BankDetails]: BankDetailsStep,
  [ProgressStep.RefundSummary]: RefundSummaryStep
};

const ProgressComponent = () => {
  const { state, ticketIndex, previousTicketIndex: prevIndex } = ProgressManager.useContainer();

  const notification = useNotification(TickedAddedNotification);

  // Don't show notification if the index is decremented or if there's only one ticket
  const shouldDisplayNotification = prevIndex < ticketIndex && state.tickets.length !== 1;

  React.useEffect(() => notification.open(() => null), [ticketIndex]);

  if (state.canceled) return <CanceledStep />;

  const Component = map[state.step];

  return (
    <>
      <Component />

      {!!shouldDisplayNotification && <Notification {...notification.props} {...{ index: ticketIndex }} />}
    </>
  );
};

export const ProgressPage: React.FC = () => {
  return (
    <ProgressManager.Provider>
      <div className="flex flex-col md:flex-row">
        <Navigation />

        <ProgressComponent />
      </div>
    </ProgressManager.Provider>
  );
};
