import { Address, Customer } from '@repay/api-sdk';
import React from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { translations } from '@/locales';
import { letterPattern } from '@/utils/patterns';

import { Alert } from '@/components/Alert';
import { BackButton } from '@/components/BackButton';
import { Form } from '@/components/Form';
import { AddressInput } from '@/components/Form/AddressInput';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { createMaskFromRegex, MaskedInput } from '@/components/Form/MaskedInput';
import { OptionalLabel } from '@/components/Form/OptionalLabel';
import { PhoneInput } from '@/components/Form/PhoneInput';
import { RadioSelect } from '@/components/Form/RadioSelect';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Step, StepFooter, StepHeader } from '@/components/Step';
import { SubmitButton } from '@/components/SubmitButton';

const schema = yup
  .object()
  .shape({
    title: yup.string().required().label(translations.fields.honorific),
    phone: yup.string().phone().required().label(translations.fields.phoneNumber),
    email: yup.string().email().required().label(translations.fields.emailAddress),
    firstName: yup
      .string()
      .matches(/^[A-Za-z-' ]+$/, translations.customValidation.onlyLetters)
      .required()
      .label(translations.fields.firstName),
    lastName: yup
      .string()
      .matches(/[a-zA-Z]/, translations.customValidation.onlyLetters)
      .required()
      .label(translations.fields.lastName),
    address: yup
      .object({
        line1: yup.string().required().label(translations.fields.addressLine1),
        line2: yup.string().label(translations.fields.addressLine2),
        line3: yup.string().label(translations.fields.addressLine3),
        city: yup
          .string()
          .matches(/[a-zA-Z]/, translations.customValidation.onlyLetters)
          .required()
          .label(translations.fields.city),
        county: yup
          .string()
          .matches(/[a-zA-Z]/, translations.customValidation.onlyLetters)
          .required()
          .label(translations.fields.county),
        country: yup
          .string()
          .matches(/[a-zA-Z]/, translations.customValidation.onlyLetters)
          .required()
          .label(translations.fields.country),
        zip: yup.string().required().label(translations.fields.zip)
      })
      .required()
  })
  .required();

interface Props {
  onBack(): void;
  onSubmit(userIdentifier: Customer): Promise<void>;
  initialValues?: Customer;
  disableEmail?: boolean;
}

export const RegisterStep: React.FC<Props> = ({ onBack, onSubmit, initialValues, disableEmail = false }) => {
  const intl = useIntl();

  return (
    <Step>
      <StepHeader title={<FormattedMessage id={translations.pages.progress.customerDetails.register.title} />} />

      <Form {...{ initialValues, schema, onSubmit }}>
        {({ submitting, form, submitError }) => (
          <React.Fragment>
            <div>
              <div className="-m-2 flex flex-wrap">
                {Object.values(translations.enum.honorifics).map((item, index) => (
                  <Field
                    key={index}
                    id={`title-${index}`}
                    name="title"
                    type="radio"
                    value={intl.formatMessage({ id: item })}
                  >
                    {({ input, meta, ...props }) => (
                      <RadioSelect {...props} {...input} className="m-2" contentClassName="text-sm">
                        <FormattedMessage id={item} />
                      </RadioSelect>
                    )}
                  </Field>
                ))}
              </div>

              <ValidatedField name="title" field={() => null} />
            </div>

            <div className="mt-4 grid gap-4 md:grid-cols-2">
              <ValidatedField
                field={InputWithLabel}
                as={MaskedInput}
                mask={createMaskFromRegex(letterPattern)}
                id="first-name"
                name="firstName"
                type="text"
                label={<FormattedMessage id={translations.fields.firstName} />}
                disabled={submitting}
              />

              <ValidatedField
                field={InputWithLabel}
                as={MaskedInput}
                mask={createMaskFromRegex(letterPattern)}
                id="last-name"
                name="lastName"
                type="text"
                label={<FormattedMessage id={translations.fields.lastName} />}
                disabled={submitting}
              />

              <ValidatedField
                field={InputWithLabel}
                as={PhoneInput}
                id="phone"
                name="phone"
                type="text"
                label={<FormattedMessage id={translations.fields.phoneNumber} />}
                disabled={submitting}
                focused
              />

              <ValidatedField
                field={InputWithLabel}
                id="email"
                name="email"
                type="text"
                label={<FormattedMessage id={translations.fields.emailAddress} />}
                disabled={submitting || disableEmail}
              />

              <ValidatedField
                field={InputWithLabel}
                as={AddressInput}
                id="address-search"
                name="address.line1"
                type="text"
                label={<FormattedMessage id={translations.fields.addressLine1} />}
                disabled={submitting}
                className="md:col-span-2"
                onChange={(value: Address) => {
                  form.change('address', value as yup.InferType<typeof schema>['address']);
                  return value.line1;
                }}
              />

              <ValidatedField
                field={InputWithLabel}
                id="address-line-2"
                name="address.line2"
                type="text"
                label={<OptionalLabel id={translations.fields.addressLine2} />}
                disabled={submitting}
              />

              <ValidatedField
                field={InputWithLabel}
                id="address-line-3"
                name="address.line3"
                type="text"
                label={<OptionalLabel id={translations.fields.addressLine3} />}
                disabled={submitting}
              />

              <ValidatedField
                field={InputWithLabel}
                as={MaskedInput}
                mask={createMaskFromRegex(letterPattern)}
                id="city"
                name="address.city"
                type="text"
                label={<FormattedMessage id={translations.fields.city} />}
                disabled={submitting}
              />

              <ValidatedField
                field={InputWithLabel}
                as={MaskedInput}
                id="county"
                mask={createMaskFromRegex(letterPattern)}
                name="address.county"
                type="text"
                label={<FormattedMessage id={translations.fields.county} />}
                disabled={submitting}
              />

              <ValidatedField
                field={InputWithLabel}
                id="country"
                as={MaskedInput}
                mask={createMaskFromRegex(letterPattern)}
                name="address.country"
                type="text"
                label={<FormattedMessage id={translations.fields.country} />}
                disabled={submitting}
              />

              <ValidatedField
                field={InputWithLabel}
                id="zip"
                name="address.zip"
                type="text"
                label={<FormattedMessage id={translations.fields.zip} />}
                disabled={submitting}
              />
            </div>

            {!!submitError && (
              <Alert className="mt-4" type="error" title={submitError?.body?.message ?? submitError?.message} />
            )}

            <StepFooter>
              <SubmitButton loading={submitting}>
                <FormattedMessage id={translations.buttons.continue} />
              </SubmitButton>

              <BackButton onClick={onBack} />
            </StepFooter>
          </React.Fragment>
        )}
      </Form>
    </Step>
  );
};
