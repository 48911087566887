import classnames from 'classnames';
import React from 'react';

import { AsComponent } from '@/components/As';

import { Input, Props as InputProps } from './Input';

const useFocus = (props: { onFocus?: React.FocusEventHandler<Element>; onBlur?: React.FocusEventHandler<Element> }) => {
  const [focused, setFocused] = React.useState(false);

  return React.useMemo(
    () => ({
      focused,
      props: {
        onFocus: (event: React.FocusEvent<HTMLElement>) => {
          props.onFocus?.(event);
          if (!event.isDefaultPrevented()) setFocused(true);
        },
        onBlur: (event: React.FocusEvent<HTMLElement>) => {
          props.onBlur?.(event);
          if (!event.isDefaultPrevented()) setFocused(false);
        }
      }
    }),
    [focused, setFocused, props]
  );
};

export type Props<T extends AsComponent> = InputProps<T> & {
  id?: string;
  invalid?: boolean;
};

export const InputWithLabel = React.forwardRef(function InputWithLabel<T extends AsComponent>(
  { label, className, focused: defaultFocused, ...props }: React.PropsWithChildren<Props<T>>,
  ref: React.Ref<any>
) {
  const { focused, props: focusProps } = useFocus(props);

  return (
    <div className={classnames('relative flex min-w-[6rem] flex-col-reverse', className)}>
      <Input {...props} {...focusProps} {...{ ref }} className="px-4 pt-4 pb-0" />

      <label
        htmlFor={props.id}
        className={classnames(
          'pointer-events-none absolute left-4 top-0 origin-[0] translate-y-4 whitespace-nowrap text-sm leading-4 text-[#003F2EB2] transition-all',
          { 'translate-y-1.5 scale-75': props.value?.toString().length > 0 || defaultFocused || focused }
        )}
      >
        {label}
      </label>
    </div>
  );
});
