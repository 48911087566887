import React from 'react';

import { Select } from './Select';

export const TimeInput: React.FC = ({ ...rest }) => {
  const items = Array.from({ length: 96 }, (_, index) => {
    const hour = (Math.floor(index / 4) + 6) % 24; // Start from 06:00
    const minute = (index % 4) * 15;
    const time = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
    return { value: time, name: time };
  });

  return <Select {...rest} {...{ items }} />;
};
