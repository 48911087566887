import { CardDetails } from '@repay/api-sdk';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

// @ts-ignore
import RealexHpp from './rxp-js.js';

import { GLOBAL_PAYMENTS_API } from '@/config';

import { apiClient } from '@/services/api-client';

import { translations } from '@/locales';

import { Alert } from '@/components/Alert';
import { Loading, LoadingIndicator } from '@/components/Loading';

interface Props {
  onSubmit(cardDetails: CardDetails): Promise<void>;
  className?: string;
}

export const CardDetailsForm: React.FC<Props> = ({ onSubmit, className }) => {
  const intl = useIntl();

  const [loading, setLoading] = React.useState(true);
  const [hppError, setHppError] = React.useState<Error>();

  const id = 'hppIframe';

  React.useEffect(() => {
    (async () => {
      try {
        const response = await apiClient.cardStorage.initiateCardStorage();

        RealexHpp.setHppUrl(GLOBAL_PAYMENTS_API);

        RealexHpp.embedded.init(
          'autoload',
          id,
          (cardResult: any) => {
            if (cardResult.RESULT !== '00') {
              setHppError(new Error(cardResult.MESSAGE));
              return;
            }

            const paymentDetails: CardDetails = {
              paymentId: cardResult.SAVED_PMT_REF,
              payerId: cardResult.SAVED_PAYER_REF,
              pasRef: cardResult.PASREF,
              orderId: cardResult.ORDER_ID,
              result: cardResult.RESULT,
              card: {
                digits: cardResult.SAVED_PMT_DIGITS,
                expiry: cardResult.SAVED_PMT_EXPDATE,
                name: cardResult.SAVED_PMT_NAME,
                type: cardResult.SAVED_PMT_TYPE
              }
            };

            onSubmit(paymentDetails).catch(() => {
              setHppError(new Error());
            });
          },
          response
        );
      } catch (e) {
        setHppError(new Error((e as Error).message));
      }
    })().finally(() => setLoading(false));

    return () => RealexHpp.embedded.destroy();
  }, [onSubmit]);

  return (
    <div {...{ className }}>
      {hppError ? (
        <Alert
          title={intl.formatMessage({ id: translations.pages.progress.purchaseMethod.card.errorTitle })}
          type="error"
        >
          <FormattedMessage id={translations.pages.progress.purchaseMethod.card.error} />
        </Alert>
      ) : (
        <>
          <Loading visible={loading}>
            <LoadingIndicator className="w-10" />
          </Loading>

          <Alert className="mb-4" type="info">
            Test card: 5425230000004415
          </Alert>

          <Alert className="mb-4" type="info">
            Visa card: 4263970000005262
          </Alert>

          <iframe className="hidden min-h-[585px] w-full" {...{ id }}></iframe>
        </>
      )}
    </div>
  );
};
