import { PurchaseLocation, RefundReason, TicketFormat, TicketType } from '@repay/api-sdk';
import dayjs from 'dayjs';
import React from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

import { translations } from '@/locales';

import { areSameDates, isDateAfterMaxMonths, isDateBefore } from '@/utils/dateFunctions';

import { ProgressManager } from '@/hooks/useProgressManager';
import { useGroupStations } from '@/hooks/useStations';
import { useStrikes } from '@/hooks/useStrikes';

import { BackButton } from '@/components/BackButton';
import { Form } from '@/components/Form';
import { DateInput, DATE_FORMAT, TIME_FORMAT } from '@/components/Form/DateInput';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { RadioSelect } from '@/components/Form/RadioSelect';
import { Select } from '@/components/Form/Select';
import { TimeInput } from '@/components/Form/TimeInput';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { HelpButton } from '@/components/HelpButton';
import { Step, StepFooter, StepHeader } from '@/components/Step';
import { SubmitButton } from '@/components/SubmitButton';
import { Tooltip } from '@/components/Tooltip';

import disruption from '@/assets/refund-reasons/train-disruption.svg';
import unused from '@/assets/refund-reasons/train-unused.svg';

const refundReasons = [
  { src: disruption, value: RefundReason.DISRUPTION },
  { src: unused, value: RefundReason.UNUSED },
  { src: unused, value: RefundReason.STRIKE_ACTION }
];

export const RefundReasonStep: React.FC = () => {
  const { back, state, ticketIndex, setTicketIndex, updateRefundReason } = ProgressManager.useContainer();

  const ticket = state.tickets[ticketIndex];

  const count = state.tickets.length;

  const { data: strikes = [] } = useStrikes();

  const { data: originGroupStations = { isGroupStation: false, stations: [] } } = useGroupStations(
    ticket.originStationId
  );
  const { data: destinationGroupStations = { isGroupStation: false, stations: [] } } = useGroupStations(
    ticket.destinationStationId
  );

  const { initialValues, schema } = React.useMemo(() => {
    const initialValues = ticket.reason
      ? {
          reason: ticket.reason.reason,
          strikeActionDate: ticket.reason.strikeActionDate,
          journeyDate:
            ticket.reason.journeyDate &&
            dayjs(ticket.reason.journeyDate, `${DATE_FORMAT} ${TIME_FORMAT}`).format(DATE_FORMAT),
          journeyTime:
            ticket.reason.journeyDate &&
            dayjs(ticket.reason.journeyDate, `${DATE_FORMAT} ${TIME_FORMAT}`).format(TIME_FORMAT)
        }
      : {};

    const schema = yup
      .object({
        reason: yup.mixed<RefundReason>().oneOf(Object.values(RefundReason)).required(),
        strikeActionDate: yup
          .string()
          .date()
          .label(translations.fields.strikeActionDate)
          .when('reason', { is: RefundReason.STRIKE_ACTION, then: (s) => s.required(), otherwise: (s) => s.strip() }),
        journeyDate: yup
          .string()
          .date()
          .test({
            name: 'maxToday',
            test: (disruptionDate) => {
              if (!disruptionDate) return true;

              const isInThePast = isDateBefore(disruptionDate, new Date());

              return isInThePast;
            },
            message: translations.customValidation.beforeCurrentDate
          })
          .test({
            name: 'max28DaysAgo',
            test: (disruptionDate) => {
              if (!disruptionDate) return true;

              const isBeforeAndWithin28Days = isDateBefore(disruptionDate, new Date(), 28);

              return isBeforeAndWithin28Days;
            },
            message: translations.customValidation.maxDaysAgo,
            params: {
              daysNumber: 28
            }
          })
          .test({
            name: 'shouldBeDateOfTravel',
            test: (disruptionDate) => {
              if (!disruptionDate) return true;

              const shouldBeDateOfTravel = [
                TicketType.ADVANCE,
                TicketType.RETURN,
                TicketType.DAY_RETURN,
                TicketType.DAY_TRAVELCARD,
                TicketType.SINGLE
              ].includes(ticket.type);

              if (!shouldBeDateOfTravel) return true;

              return areSameDates(disruptionDate, ticket.dateOfTravel ?? ticket.validFrom);
            },
            // Should I add some additional messages here? If the date of travel is more that 28 days in the past,
            // The user will get 'should be {dateOfTravel}' and 'can't be more than 28 days ago' messages
            // Maybe I could combine these into one message in that case.
            message: translations.customValidation.sameDate,
            params: {
              date: ticket.dateOfTravel ?? ticket.validFrom
            }
          })
          .test({
            name: 'maxOneMonthFromDateOfTravel',
            test: (disruptionDate) => {
              if (!disruptionDate) return true;

              const isReturnPartial = ticket.type === TicketType.RETURN_PARTIAL;

              if (!isReturnPartial) return true;

              return isDateAfterMaxMonths(disruptionDate, ticket.dateOfTravel ?? ticket.validFrom);
            },
            // Same question as above, only here we will be in that situation when dateOfTravel
            // is more than a month and 28 days ago
            message: translations.customValidation.withinAMonth,
            params: {
              date: ticket.dateOfTravel ?? ticket.validFrom
            }
          })
          .label(translations.fields.disruptionDate)
          .when('reason', { is: RefundReason.DISRUPTION, then: (s) => s.required(), otherwise: (s) => s.strip() }),
        journeyTime: yup
          .string()
          .matches(/\d{2}:\d{2}/, translations.validation.mixed.default)
          .label(translations.fields.disruptionTime)
          .when('reason', { is: RefundReason.DISRUPTION, then: (s) => s.required(), otherwise: (s) => s.strip() }),
        origin: yup
          .string()
          .label(translations.fields.origin)
          .when('reason', {
            is: RefundReason.DISRUPTION,
            then: (s) => (originGroupStations.isGroupStation ? s.required() : s.strip()),
            otherwise: (s) => s.strip()
          }),
        destination: yup
          .string()
          .label(translations.fields.destination)
          .when('reason', {
            is: RefundReason.DISRUPTION,
            then: (s) => (destinationGroupStations.isGroupStation ? s.required() : s.strip()),
            otherwise: (s) => s.strip()
          })
      })
      .required();

    return {
      initialValues,
      schema
    };
  }, [
    destinationGroupStations.isGroupStation,
    originGroupStations.isGroupStation,
    ticket.dateOfTravel,
    ticket.reason,
    ticket.type,
    ticket.validFrom
  ]);

  const onSubmit = React.useCallback(
    ({ reason, strikeActionDate, journeyDate, journeyTime, origin, destination }: yup.InferType<typeof schema>) => {
      updateRefundReason(
        {
          reason,
          strikeActionDate: reason === RefundReason.STRIKE_ACTION ? strikeActionDate : undefined,
          journeyDate: reason === RefundReason.DISRUPTION ? `${journeyDate} ${journeyTime}` : undefined
        },
        {
          origin: RefundReason.DISRUPTION && originGroupStations.isGroupStation ? origin : undefined,
          destination: RefundReason.DISRUPTION && destinationGroupStations.isGroupStation ? destination : undefined
        }
      );
    },
    [destinationGroupStations.isGroupStation, originGroupStations.isGroupStation, updateRefundReason]
  );

  return (
    <React.Fragment>
      <Step>
        <StepHeader
          title={
            <FormattedMessage
              id={translations.pages.progress.refundReason.title}
              values={{ count, index: ticketIndex + 1 }}
            />
          }
        />

        <Form
          {...{
            initialValues,
            schema,
            onSubmit
          }}
        >
          {({ values, submitting, invalid }) => (
            <React.Fragment>
              <div className="grid max-w-4xl gap-4 md:grid-cols-2">
                <div>
                  <div className="space-y-4">
                    {refundReasons.map(({ src, value }) => {
                      const notShowForRangerorRover =
                        value === RefundReason.DISRUPTION &&
                        (ticket.type === TicketType.RANGER || ticket.type === TicketType.ROVER);

                      const notShowForAdvance =
                        ticket.type === TicketType.ADVANCE &&
                        (value === RefundReason.UNUSED ||
                          // Don't show disruption if it's more that 28 days ago
                          // In the docs, this is specified only for Advance tickets, but shouldn't it apply to all?
                          (value === RefundReason.DISRUPTION &&
                            !isDateBefore(ticket.dateOfTravel ?? ticket.validFrom, new Date(), 28)));

                      if (notShowForRangerorRover || notShowForAdvance) return null;

                      return (
                        <Field key={value} id={`refund-reason-${value}`} name="reason" type="radio" {...{ value }}>
                          {({ input, meta, ...props }) => (
                            <RadioSelect
                              {...props}
                              {...input}
                              className="relative"
                              contentClassName="flex items-center"
                            >
                              <img {...{ src }} className="mr-4 h-5 w-5" />
                              <FormattedMessage id={translations.enum.refundReason[value]} />

                              <Tooltip
                                content={
                                  <div className="whitespace-nowrap">
                                    {value === RefundReason.DISRUPTION
                                      ? 'Journey abandoned due to a disruption.'
                                      : value === RefundReason.UNUSED
                                      ? 'The ticket remained unused.'
                                      : value === RefundReason.STRIKE_ACTION
                                      ? 'Journey abandoned due to a strike action.'
                                      : undefined}
                                  </div>
                                }
                                className="absolute top-2 right-2"
                              >
                                <div className="p-2">
                                  <HelpButton className="-my-[3px]" />
                                </div>
                              </Tooltip>
                            </RadioSelect>
                          )}
                        </Field>
                      );
                    })}
                  </div>

                  {(values.reason === RefundReason.DISRUPTION || values.reason === RefundReason.STRIKE_ACTION) && (
                    <React.Fragment>
                      <div className="mt-8 font-medium">
                        <FormattedMessage
                          id={translations.pages.progress.refundReason.details}
                          values={{
                            reason: <FormattedMessage id={translations.enum.refundReason[values.reason]} />
                          }}
                        />
                      </div>

                      <div className="mt-2">
                        {values.reason === RefundReason.STRIKE_ACTION && (
                          <ValidatedField
                            field={InputWithLabel}
                            as={Select}
                            items={strikes.map((strike) => ({
                              value: dayjs(strike.eventDate).format(DATE_FORMAT),
                              name: dayjs(strike.eventDate).format(DATE_FORMAT)
                            }))}
                            id="strike-action-date"
                            name="strikeActionDate"
                            mask={DATE_FORMAT}
                            type="text"
                            label={<FormattedMessage id={translations.fields.strikeActionDate} />}
                            disabled={submitting}
                          />
                        )}

                        {values.reason === RefundReason.DISRUPTION && (
                          <div className="space-y-4">
                            <ValidatedField
                              field={InputWithLabel}
                              as={DateInput}
                              id="journey-date"
                              name="journeyDate"
                              mask={DATE_FORMAT}
                              type="text"
                              label={
                                <>
                                  <FormattedMessage id={translations.fields.disruptionDate} />
                                  <span className="ml-1 text-xs lowercase">({DATE_FORMAT})</span>
                                </>
                              }
                              disabled={submitting}
                              className="w-full"
                            />

                            <ValidatedField
                              field={InputWithLabel}
                              as={TimeInput}
                              id="journey-time"
                              name="journeyTime"
                              label={<FormattedMessage id={translations.fields.disruptionTime} />}
                              disabled={submitting}
                              className="w-full"
                            />

                            <div className="flex gap-4">
                              {originGroupStations.isGroupStation && (
                                <ValidatedField
                                  field={InputWithLabel}
                                  as={Select}
                                  className="w-full"
                                  items={originGroupStations.stations!.map((station) => ({
                                    value: station.id,
                                    name: station.name
                                  }))}
                                  id="origin"
                                  name="origin"
                                  label={<FormattedMessage id={translations.fields.origin} />}
                                  disabled={submitting}
                                />
                              )}

                              {destinationGroupStations.isGroupStation && (
                                <ValidatedField
                                  field={InputWithLabel}
                                  as={Select}
                                  className="w-full"
                                  items={destinationGroupStations.stations!.map((station) => ({
                                    value: station.id,
                                    name: station.name
                                  }))}
                                  id="destination"
                                  name="destination"
                                  label={<FormattedMessage id={translations.fields.destination} />}
                                  disabled={submitting}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>

              <StepFooter>
                <SubmitButton disabled={invalid}>
                  <FormattedMessage id={translations.buttons.continue} />
                </SubmitButton>

                <BackButton
                  onClick={() => {
                    const isOnlineOrSmartcard =
                      state.purchaseLocation === PurchaseLocation.ONLINE ||
                      state.ticketFormat === TicketFormat.SMARTCARD;

                    // This is the first step of a refund reason cycle if the tickets are online or smartcard (selectable)
                    // In this case, going back takes us to the previous ticket
                    if (ticketIndex > 0 && isOnlineOrSmartcard) setTicketIndex((index) => index - 1);

                    back();
                  }}
                />
              </StepFooter>
            </React.Fragment>
          )}
        </Form>
      </Step>
    </React.Fragment>
  );
};
