import { Customer, PurchaseLocation } from '@repay/api-sdk';
import React from 'react';
import { usePrevious } from 'react-use';

import { ProgressManager } from '@/hooks/useProgressManager';
import { apiClient } from '@/services/api-client';

import { useAuth } from '@/hooks/useAuth';

import { CheckUserStep } from './CheckUserStep';
import { CustomerDetailsStep } from './CustomerDetailsStep';
import { LoginStep } from './LoginStep';
import { OtpStep } from './OtpStep';
import { RegisterStep } from './RegisterStep';

enum CustomerStep {
  Check = 'check',
  Login = 'login',
  Register = 'register',
  UpdateCustomer = 'updateCustomer',
  Otp = 'otp',
  Details = 'details'
}

export const ContactDetailsStep: React.FC = () => {
  const { state, back, updateCustomer, setTicketIndex } = ProgressManager.useContainer();
  const [step, setStep] = React.useState<CustomerStep>(
    state.customer ? (state.customer.validated ? CustomerStep.Details : CustomerStep.Login) : CustomerStep.Check
  );
  const [provisionalCustomer, setProvisionalCustomer] = React.useState<Customer | undefined>(state?.customer);

  const { verifyOtp, sendOtp } = useAuth();

  const previousStep = usePrevious(step);

  const onBack = React.useCallback(() => {
    if (step !== previousStep && previousStep) return setStep(previousStep);

    setTicketIndex(state.tickets.length - 1);

    return back();
  }, [step, previousStep, setTicketIndex, state.tickets.length, back]);

  if (step === CustomerStep.Check)
    return (
      <CheckUserStep
        onBack={() => {
          setTicketIndex(state.tickets.length - 1);
          back();
        }}
        onSubmit={(login) => setStep(login ? CustomerStep.Login : CustomerStep.Register)}
      />
    );

  if (step === CustomerStep.Login)
    return (
      <LoginStep
        customer={state.customer}
        onBack={() => {
          if (state.purchaseLocation === PurchaseLocation.ONLINE) {
            setTicketIndex(state.tickets.length - 1);
            back();
          } else setStep(CustomerStep.Check);
        }}
        onSubmit={async (userIdentifier) => {
          const requestBody = { email: userIdentifier.email! };
          await sendOtp(requestBody);

          return setStep(CustomerStep.Otp);
        }}
      />
    );

  if (step === CustomerStep.Register)
    return (
      <RegisterStep
        onBack={() => {
          if (state.purchaseLocation === PurchaseLocation.ONLINE) {
            setTicketIndex(state.tickets.length - 1);
            back();
          } else setStep(CustomerStep.Check);
        }}
        onSubmit={async (customer) => {
          await apiClient.authentication.register({ requestBody: customer });

          setProvisionalCustomer(customer);
          setStep(CustomerStep.Otp);
        }}
        initialValues={provisionalCustomer}
      />
    );

  if (step === CustomerStep.Otp)
    return (
      <OtpStep
        {...{ onBack }}
        onSubmit={async (code) => {
          const { customer } = await verifyOtp({
            code
          });

          updateCustomer({ ...customer, validated: true });
        }}
      />
    );

  if (step === CustomerStep.UpdateCustomer)
    return (
      <RegisterStep
        {...{ onBack }}
        onSubmit={async (customer) => {
          await apiClient.customer.updateCustomer({ requestBody: customer });
          updateCustomer(customer);
        }}
        disableEmail
        initialValues={provisionalCustomer}
      />
    );

  if (step === CustomerStep.Details)
    return (
      <CustomerDetailsStep
        customer={state.customer!}
        onBack={() => {
          setTicketIndex(state.tickets.length - 1);
          back();
        }}
        onSubmit={() => updateCustomer(state.customer!)}
        onAction={() => setStep(CustomerStep.UpdateCustomer)}
      />
    );

  return null;
};
