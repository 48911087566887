import { Address, AddressSearchResult } from '@repay/api-sdk';
import React from 'react';
import { useAsyncFn } from 'react-use';

import { Item, Select } from '@/components/Form/Select';
import { apiClient } from '@/services/api-client';

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
  value?: string;
  onChange(value: Partial<Address>): void;
}

interface AutocompleteItem extends Item<Partial<Omit<AddressSearchResult, 'id' | 'name'>>> {
  id: string;
}

export const AddressInput: React.FC<Props> = ({ value, onChange, ...props }) => {
  const [{ value: items = [] }, onInputValueChange] = useAsyncFn(async (query: string): Promise<AutocompleteItem[]> => {
    if (query.length < 1) return [];

    const queryItem: AutocompleteItem = { id: query, name: query, value: { line1: query } };

    if (query.length < 3) return [queryItem];

    const results = await apiClient.address.autocompleteAddress({ query });

    return [queryItem, ...results.map(({ id, name, ...value }) => ({ id, name, value }))];
  }, []);

  return (
    <Select<Partial<Address>, AutocompleteItem>
      {...props}
      {...{ items, onInputValueChange, onChange }}
      editable
      getInputValue={(isOpen, inputValue) => (isOpen ? inputValue : value ?? '')}
      contain
    />
  );
};
