import classnames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export interface ValidationError {
  id: string;
  values?: any;
}

export interface Props extends ValidationError {
  visible: boolean;
  className?: string;
}

export const ValidationMessage: React.FC<React.PropsWithChildren<Props>> = ({
  visible,
  id,
  values,
  className,
  ...rest
}) => {
  const intl = useIntl();

  const formattedValues = {
    ...values,
    path: values?.label ? intl.formatMessage({ id: values.label }).toLowerCase() : values?.path
  };

  return (
    <div
      className={classnames(
        'text-left text-sm text-red-600',
        visible ? 'visible mt-1 opacity-100' : 'hidden',
        className
      )}
      role="alert"
      {...rest}
    >
      {!!id && <FormattedMessage {...{ id }} values={formattedValues} />}
    </div>
  );
};
