import { Ticket, TicketFormat, TicketImage, TicketType } from '@repay/api-sdk';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

import { translations } from '@/locales';

import { isTicketTypeSeason } from '@/utils/is-ticket-type-season';

import { apiClient } from '@/services/api-client';

import { useModal } from '@/hooks/useModal';
import { RefundTicket } from '@/hooks/useProgressManager';

import { Alert } from '@/components/Alert';
import { BackButton } from '@/components/BackButton';
import { Form } from '@/components/Form';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { HelpButton } from '@/components/HelpButton';
import { Modal } from '@/components/Modal';
import { StepFooter } from '@/components/Step';
import { SubmitButton } from '@/components/SubmitButton';

import { TicketImageHelpModal } from '../../modals/TicketImageHelpModal';
import { TicketImageUpload } from './TicketImageUpload';

interface Props {
  ticket: Pick<RefundTicket, 'number' | 'utn' | 'scn' | 'type' | 'format' | 'image' | 'reason'> & { id?: number };
  onSubmit(values: Partial<Record<TicketImage, string>> & { imagesIds?: number[] }): void;
  onBack?: () => void;
  className?: string;
  submitButtonMessage?: React.ReactNode;
}

const schema = yup
  .object({
    type: yup.string().optional(),
    format: yup.string().required(),
    [TicketImage.TICKET]: yup
      .mixed<File | string>()
      .test({
        test: (value) => !value || typeof value === 'string' || value instanceof File,
        message: translations.customValidation.fileType
      })
      .test({
        test: (value) => !value || typeof value === 'string' || (value instanceof File && value.size <= maxFileSize),
        message: translations.customValidation.fileSize,
        params: { size: 10 }
      })
      .when(['type', 'format'], {
        is: (type: TicketType, format: TicketFormat) =>
          getImageTypesForTicket({ type, format }).includes(TicketImage.TICKET),
        then: (s) => s.required()
      })
      .label(translations.fields.ticketImage),
    [TicketImage.DEFACED]: yup
      .mixed<File | string>()
      .test({
        test: (value) => !value || typeof value === 'string' || value instanceof File,
        message: translations.customValidation.fileType
      })
      .test({
        test: (value) => !value || typeof value === 'string' || (value instanceof File && value.size <= maxFileSize),
        message: translations.customValidation.fileSize,
        params: { size: 10 }
      })
      .when(['type', 'format'], {
        is: (type: TicketType, format: TicketFormat) =>
          getImageTypesForTicket({ type, format }).includes(TicketImage.DEFACED),
        then: (s) => s.required()
      })
      .label(translations.fields.ticketDefacedImage),
    [TicketImage.PHOTOCARD]: yup
      .mixed<File | string>()
      .test({
        test: (value) => !value || typeof value === 'string' || value instanceof File,
        message: translations.customValidation.fileType
      })
      .test({
        test: (value) => !value || typeof value === 'string' || (value instanceof File && value.size <= maxFileSize),
        message: translations.customValidation.fileSize,
        params: { size: 10 }
      })
      .when(['type', 'format'], {
        is: (type: TicketType, format: TicketFormat) =>
          getImageTypesForTicket({ type, format }).includes(TicketImage.PHOTOCARD),
        then: (s) => s.required()
      })
      .label(translations.fields.ticketPhotocardImage)
  })
  .required()
  .label(translations.fields.ticketImage);

export const maxFileSize = 10 * 1000 * 1000;

const getImageTypesForTicket = (ticket: Pick<Ticket, 'format' | 'type'>) => {
  if (ticket.format === TicketFormat.BARCODE) return [TicketImage.TICKET];
  if (ticket.format === TicketFormat.SMARTCARD) return [];
  if (isTicketTypeSeason(ticket.type)) return [TicketImage.TICKET, TicketImage.DEFACED, TicketImage.PHOTOCARD];
  return [TicketImage.TICKET, TicketImage.DEFACED];
};

export const TicketImagesForm: React.FC<Props> = ({ ticket, onBack, onSubmit, submitButtonMessage, className }) => {
  const imageTypes = getImageTypesForTicket(ticket);

  const { initialValues } = React.useMemo(() => {
    const { type, format, image } = ticket;

    const initialValues = {
      type,
      format,
      [TicketImage.TICKET]: image?.[TicketImage.TICKET],
      [TicketImage.DEFACED]: image?.[TicketImage.DEFACED],
      [TicketImage.PHOTOCARD]: image?.[TicketImage.PHOTOCARD]
    };

    return { initialValues };
  }, [ticket]);

  const handleSubmit = React.useCallback(
    async ({ type, format, ...image }: yup.InferType<typeof schema>) => {
      const imagesIds: Array<number> = [];

      const ticketImages = await Object.entries(image).reduce<Promise<Partial<Record<TicketImage, string>>>>(
        async (promise, [key, file]) => {
          const imageType = key as TicketImage;
          const data = await promise;

          if (!file) return data;
          if (typeof file === 'string') return { ...data, [imageType]: file };

          const { url, headers, ticketImageId } = await apiClient.ticket.getTicketImageUrl({
            type: imageType,
            filename: file.name,
            claimId: ticket?.id
          });
          await fetch(url, { method: 'put', headers, body: file });

          ticketImageId && imagesIds.push(ticketImageId);

          return { ...data, [imageType]: url };
        },
        Promise.resolve({})
      );

      onSubmit({ ...ticketImages, imagesIds });
    },
    [onSubmit, ticket?.id]
  );

  const modal = useModal(TicketImageHelpModal);

  return (
    <React.Fragment>
      <Form {...{ initialValues, schema, onSubmit: handleSubmit, className }}>
        {({ submitting, submitError }) => (
          <React.Fragment>
            <div className="grid max-w-4xl gap-4 md:grid-cols-2">
              <div className={classnames('space-y-2', imageTypes.length > 1 && 'md:col-span-2')}>
                <div className="text-sm">
                  <FormattedMessage
                    id={translations.pages.progress.ticketImages.ticketNumber}
                    values={{ number: ticket.number ?? ticket.utn ?? ticket.scn }}
                  />
                </div>

                <div className={classnames('grid gap-4', imageTypes.length > 1 && 'md:grid-cols-2')}>
                  {imageTypes.map((imageType) => (
                    <div key={imageType} className="relative">
                      <ValidatedField
                        field={TicketImageUpload}
                        id={`ticket-image-${imageType}`}
                        name={`${imageType}`}
                        readOnly={submitting}
                      >
                        <FormattedMessage
                          id={translations.pages.progress.ticketImages.add[imageType]}
                          values={{ count: 1 }}
                        />
                      </ValidatedField>

                      <HelpButton
                        className="absolute top-4 right-4 -my-[2px]"
                        onClick={() =>
                          modal.open(() => null, {
                            imageType,
                            count: 1,
                            refundReason: ticket.reason?.reason
                          })
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <StepFooter>
              <SubmitButton loading={submitting}>
                {submitButtonMessage ?? <FormattedMessage id={translations.buttons.continue} />}
              </SubmitButton>

              {!!onBack && <BackButton onClick={onBack} />}
            </StepFooter>

            {!!submitError && (
              <Alert className="mt-4" type="error">
                {submitError?.body?.message ?? submitError?.message}
              </Alert>
            )}
          </React.Fragment>
        )}
      </Form>

      <Modal {...modal.props} />
    </React.Fragment>
  );
};
