/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum RefundReason {
  DISRUPTION = 'Disruption',
  UNUSED = 'Unused',
  STRIKE_ACTION = 'Strike_action',
}
