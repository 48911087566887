/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BankAccount } from '../models/BankAccount';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PaymentMethodService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Validates the bank account.
   * @returns any Bank account validated
   * @throws ApiError
   */
  public validateBankAccount({
    requestBody,
  }: {
    requestBody: BankAccount,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/payment-methods/bank-accounts/validate',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
