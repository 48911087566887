import React from 'react';

import { InstanceProps, OpenNotification } from '@/components/Notification';

export const useNotification = <P extends InstanceProps<any[]>>(notification: React.ComponentType<P>) => {
  const [onAction, setOnAction] = React.useState<P['onAction']>();

  return React.useMemo(
    () => ({
      props: {
        isOpen: !!onAction,
        notification,
        onAction: ((...args) => {
          onAction?.(...args);
          setOnAction(undefined);
        }) as P['onAction'],
        onClose: () => setOnAction(undefined)
      },
      open: ((onAction) => setOnAction(() => onAction)) as OpenNotification<P>
    }),
    [notification, onAction, setOnAction]
  );
};
