import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

import { useModal } from '@/hooks/useModal';
import { ProgressManager } from '@/hooks/useProgressManager';
import { translations } from '@/locales';

import { Alert } from '@/components/Alert';
import { BackButton } from '@/components/BackButton';
import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { MaskedInput } from '@/components/Form/MaskedInput';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { HelpButton } from '@/components/HelpButton';
import { Modal } from '@/components/Modal';
import { Step, StepFooter, StepHeader } from '@/components/Step';
import { SubmitButton } from '@/components/SubmitButton';

import { TicketSmartcardHelpModal } from '../modals/TicketSmartcardHelpModal';

const schema = yup
  .object({
    scn: yup.string().required().label(translations.fields.scn)
  })
  .required();

export const SearchTicketsBySmartcardStep: React.FC = () => {
  const { back, updateSmartcardTicket } = ProgressManager.useContainer();

  const ticketSmartcardModal = useModal(TicketSmartcardHelpModal);

  const onSubmit = React.useCallback(
    ({ scn }: yup.InferType<typeof schema>) => {
      // return apiClient.ticket.getTicketByScn({ scn }).then((ticket) => updateSmartcardTickets([ticket]));
      updateSmartcardTicket(scn);
    },
    [updateSmartcardTicket]
  );

  return (
    <React.Fragment>
      <Step>
        <StepHeader title={<FormattedMessage id={translations.pages.progress.searchTicketsBySmartcard.title} />} />

        <Form {...{ schema, onSubmit }}>
          {({ submitting, submitError }) => (
            <React.Fragment>
              <div className="space-y-4">
                <Alert type="info" title="Test instructions:">
                  <div>
                    Smart card number: <span className="font-medium">VALID</span>
                  </div>
                </Alert>

                {!!submitError && <Alert type="error" title="We didn't find your ticket"></Alert>}

                <div className="grid gap-4 md:grid-cols-2">
                  <div className="relative">
                    <ValidatedField
                      field={InputWithLabel}
                      as={MaskedInput}
                      mask={Array.from({ length: 11 }).map(() => /[a-zA-Z0-9]/)}
                      id="scn"
                      name="scn"
                      type="text"
                      label={<FormattedMessage id={translations.fields.scn} />}
                      disabled={submitting}
                    />

                    <HelpButton
                      className="absolute top-4 right-4 -my-[2px]"
                      onClick={() => ticketSmartcardModal.open(() => null, undefined)}
                    />
                  </div>
                </div>
              </div>

              <StepFooter>
                <SubmitButton loading={submitting}>
                  <FormattedMessage id={translations.buttons.continue} />
                </SubmitButton>

                <BackButton onClick={() => back()} />
              </StepFooter>
            </React.Fragment>
          )}
        </Form>
      </Step>

      <Modal {...ticketSmartcardModal.props} />
    </React.Fragment>
  );
};
