import { Dialog, Transition } from '@headlessui/react';
import React from 'react';

export interface InstanceProps<T extends any[], D> {
  data: D;
  onAction: OnAction<T>;
  onClose(): void;
}

export type Props<P extends InstanceProps<any[], any>> = {
  modal: React.ComponentType<P>;
  isOpen: boolean;
} & P;

export interface OnAction<T extends any[]> {
  (...args: T): any;
}

export interface OpenModal<P extends InstanceProps<any[], any>> {
  (onAction: P['onAction'], data: P['data']): void;
}

export const Modal = <P extends InstanceProps<any[], any>>({
  modal: Component,
  isOpen,
  onAction,
  onClose,
  ...rest
}: Props<P>) => (
  <Transition show={isOpen} as={React.Fragment}>
    <Dialog as="div" static {...{ onClose }} className="fixed inset-0 z-40 overflow-y-auto">
      <Transition.Child
        as={React.Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog.Overlay className="fixed inset-0 bg-black/30" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="relative mx-auto rounded-lg bg-white p-8">
              {/* @ts-ignore */}
              <Component {...rest} {...{ onAction, onClose }} />
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
);
