import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

export const OptionalLabel: React.FC<{ id: string }> = ({ id }) => (
  <>
    <FormattedMessage id={id} />

    <span className="ml-1 lowercase">
      <FormattedMessage id={translations.fields.optional} />
    </span>
  </>
);
