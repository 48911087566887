import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon
} from '@heroicons/react/20/solid';
import classnames from 'classnames';
import React from 'react';

export type AlertType = 'info' | 'success' | 'warning' | 'error';

interface AlertStyle {
  background: string;
  icon: {
    component: React.ComponentType<React.ComponentProps<'svg'>>;
    color: string;
  };
}

const styles: Record<AlertType, AlertStyle> = {
  info: {
    background: 'bg-gray-50',
    icon: { component: InformationCircleIcon, color: 'text-gray-400' }
  },
  success: {
    background: 'bg-green-50',
    icon: { component: CheckCircleIcon, color: 'text-green-400' }
  },
  warning: {
    background: 'bg-yellow-50',
    icon: { component: ExclamationTriangleIcon, color: 'text-yellow-400' }
  },
  error: {
    background: 'bg-red-50',
    icon: { component: XCircleIcon, color: 'text-red-400' }
  }
};

interface Props {
  type: AlertType;
  title?: string | React.ReactNode;
  className?: string;
}

export const Alert: React.FC<React.PropsWithChildren<Props>> = ({ type, title, className, children }) => {
  const style = styles[type];

  return (
    <div className={classnames('rounded-md p-4', !!style && style.background, className)}>
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <style.icon.component className={classnames('h-5 w-5', !!style && style.icon.color)} aria-hidden="true" />
        </div>
        <div className="ml-3">
          {!!title && <h3 className="text-sm font-medium">{title}</h3>}
          {!!children && <div className="text-sm">{children}</div>}
        </div>
      </div>
    </div>
  );
};
