/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TicketType {
  ADVANCE = 'advance',
  SINGLE = 'single',
  RETURN = 'return',
  RETURN_PARTIAL = 'return_partial',
  DAY_RETURN = 'day_return',
  DAY_RETURN_PARTIAL = 'day_return_partial',
  DAY_TRAVELCARD = 'day_travelcard',
  CARNET = 'carnet',
  WEEKLY = 'weekly',
  FLEXI = 'flexi',
  ROVER = 'rover',
  RANGER = 'ranger',
  MONTHLY = 'monthly',
  MONTHLY_PLUS = 'monthly_plus',
  ANNUAL = 'annual',
}
