import { CheckIcon } from '@heroicons/react/20/solid';
import { ApiError, RefundStatus } from '@repay/api-sdk';
import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import useSWR from 'swr';

import { translations } from '@/locales';

import { apiClient } from '@/services/api-client';

import { Alert } from '@/components/Alert';
import { Button } from '@/components/Button';
import { Loading, LoadingIndicator } from '@/components/Loading';

import { intlFormatCurrency } from '@/utils/currencyFunctions';
import { intlFormatDate } from '@/utils/dateFunctions';
import progress from './progress.svg';

interface Props {
  status: RefundStatus;
  active: boolean;
  completed: boolean;
}

enum StatusMessagesKey {
  InvalidPaymentDetails = 'InvalidPaymentDetails',
  FailedPaymentBacs = 'FailedPaymentBacs',
  FailedPaymentCard = 'FailedPaymentCard',
  FailedBacs = 'FailedBacs',
  PaidSeasonal = 'PaidSeasonal',
  PaidNormal = 'PaidNormal',
  TicketNotDefacedEnough = 'TicketNotDefacedEnough',
  PoorQualityImage = 'PoorQualityImage',
  TicketNotPresent = 'TicketNotPresent',
  TicketDetailsDoNotMatchTicketImage = 'TicketDetailsDoNotMatchTicketImage',
  TicketImagesDoNotMatch = 'TicketImagesDoNotMatch',
  OnlineBookingNotFound = 'OnlineBookingNotFound',
  ProofOfPurchaseRequired = 'ProofOfPurchaseRequired',
  InvalidRefundRequest = 'InvalidRefundRequest',
  NoRemainingValueSeasonTicketOnly = 'NoRemainingValueSeasonTicketOnly',
  NoRemainingValueDayTicketOnly = 'NoRemainingValueDayTicketOnly',
  NotSoldByGTR = 'NotSoldByGTR',
  OysterProduct = 'OysterProduct',
  MultipleTicketsSubmitted = 'MultipleTicketsSubmitted',
  DuplicateClaim = 'DuplicateClaim',
  PartialReturnOutwardPortion = 'PartialReturnOutwardPortion',
  InvalidAdvanceTicket = 'InvalidAdvanceTicket',
  NotSubmittedWithin56Days = 'NotSubmittedWithin56Days',
  NotSubmittedWithin28Days = 'NotSubmittedWithin28Days'
}

const RefundStatusItem: React.FC<Props> = ({ status, active, completed }) => (
  <div className="group relative">
    <div
      className={classnames('flex space-x-4 pb-10 md:flex-col md:items-center md:text-center', {
        'opacity-50': !active && !completed
      })}
    >
      <div
        className={classnames(
          'mt-1 flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full ring-2 ring-[#86BC24] md:h-8 md:w-8',
          { 'bg-[#86BC24]': active || completed }
        )}
      >
        {completed ? (
          <CheckIcon className="h-4 w-4 md:h-5 md:w-5" />
        ) : (
          active && <img src={progress} className="w-4 md:w-5" />
        )}
      </div>

      <div>
        <div className="font-bold md:mt-4">
          <FormattedMessage id={translations.pages.refundStatus.refundStatus[status]} />
        </div>
        <div className="text-sm md:text-base">
          <FormattedMessage id={translations.pages.refundStatus.statusMessage[status]} />
        </div>
      </div>
    </div>

    <div className="absolute left-0 top-8 bottom-0 flex w-6 items-center justify-center group-last:hidden md:left-1/2 md:top-0 md:h-10 md:w-full">
      <div className="h-[60%] w-[2px] bg-[#F3F0E8] md:h-[2px] md:w-[60%]" />
    </div>
  </div>
);

export const RefundLayout: React.FC<
  PropsWithChildren<{ claimRef?: string; created?: string; lastUpdated?: string; loading?: boolean; error?: ApiError }>
> = ({ children, ...rest }) => {
  return (
    <div className="flex h-full min-h-screen flex-col">
      <header className="flex flex-shrink-0 flex-col items-center justify-center bg-[#003F2E] p-6 text-white">
        {rest.loading ? (
          <Loading visible={rest.loading}>
            <LoadingIndicator className="w-8" />
          </Loading>
        ) : (
          <div className="flex flex-col items-center justify-center space-y-4">
            <div className="text-center text-2xl font-bold">
              <FormattedMessage id={translations.pages.refundStatus.refundNumber} values={{ id: rest?.claimRef }} />
            </div>

            <div className="text-sm sm:text-lg">
              <FormattedMessage
                id={translations.pages.refundStatus.daySubmitted}
                values={{
                  date: <FormattedDate value={rest.lastUpdated} dateStyle="long" timeStyle="short" dayPeriod="short" />
                }}
              />
            </div>

            <div className="text-sm sm:text-lg">
              <FormattedMessage
                id={translations.pages.refundStatus.lastUpdated}
                values={{
                  date: <FormattedDate value={rest.lastUpdated} dateStyle="long" timeStyle="short" dayPeriod="short" />
                }}
              />
            </div>
          </div>
        )}
      </header>

      {rest.loading ? (
        <div className="my-auto mx-auto">
          <Loading visible={rest.loading}>
            <LoadingIndicator className="w-8" />
          </Loading>
        </div>
      ) : rest.error ? (
        <Alert
          className="mx-auto my-auto w-1/4"
          type="error"
          title={rest.error?.body?.message ?? 'Could not load refund.'}
        />
      ) : (
        children
      )}
    </div>
  );
};

export const RefundStatusPage: React.FC = () => {
  const params = useParams();

  const intl = useIntl();

  const { claimRef, hash: statusHash } = params;

  const {
    data: refund,
    isLoading: loading,
    error
  } = useSWR(`getRefund`, () => apiClient.refund.getRefund({ claimRef: claimRef!, statusHash }), {
    revalidateOnMount: true,
    revalidateIfStale: true,
    revalidateOnFocus: false
  });

  const season = false;

  const statusToDisplay = refund?.status;

  const messagesKey = refund?.messagesKey as StatusMessagesKey;

  const { statuses, completedIndex } = React.useMemo(() => {
    const statuses = [
      RefundStatus.SUBMITTED,
      RefundStatus.PROCESSING,
      statusToDisplay === RefundStatus.REJECTED ? RefundStatus.REJECTED : RefundStatus.APPROVED,
      RefundStatus.CLOSED
    ];

    const completedIndex = statuses.indexOf(statusToDisplay ?? RefundStatus.SUBMITTED) - 1;

    return { statuses, completedIndex };
  }, [statusToDisplay]);

  const tflUrl = (
    <a className="font-semibold" href="https://tfl.gov.uk/fares/refunds-and-replacements/coronavirus">
      https://tfl.gov.uk/fares/refunds-and-replacements/coronavirus
    </a>
  );

  const adminFee = refund?.adminFeeApplicable ?? refund?.adminFeeString?.replace(/\d+-/, '') ?? 0;

  const values = {
    updateHash: refund?.updateHash,
    claimRef: refund?.claimRef,
    refundAmount: intlFormatCurrency(intl, refund?.refundAmount),
    paymentDetailsId: refund?.paymentDetailsId,
    type: refund?.type,
    number: refund?.number,
    scn: refund?.scn,
    utn: refund?.utn,
    bookingReference: refund?.bookingReference,
    purchaseDate: intlFormatDate(intl, refund?.purchaseDate),
    value: intlFormatCurrency(intl, refund?.value),
    validFrom: intlFormatDate(intl, refund?.validFrom),
    validTo: intlFormatDate(intl, refund?.validTo),
    adminFeeApplicable: intlFormatCurrency(intl, refund?.adminFeeApplicable),
    adminFee,
    numFullMonthsUsedOnTicket: 0,
    numAdditionalDaysOnTicket: 0,
    costOfDaysUsed: 0,
    tflUrl
  };

  const title = messagesKey
    ? intl.formatMessage(
        {
          id: translations.pages.refundStatus.messages?.[messagesKey]?.title
        },
        values
      )
    : undefined;

  const text = messagesKey
    ? intl.formatMessage(
        {
          id: translations.pages.refundStatus.messages?.[messagesKey]?.text
        },
        values
      )
    : undefined;

  const url = refund?.updateUrl;

  const buttonText = messagesKey
    ? intl.formatMessage(
        {
          id: translations.pages.refundStatus.messages?.[messagesKey]?.buttonText
        },
        values
      )
    : undefined;

  return (
    <RefundLayout {...{ claimRef, loading, error }} lastUpdated={refund?.lastUpdated} created={refund?.created}>
      <div className="my-auto max-w-[1600px] flex-shrink-0 p-4 md:mx-auto md:p-16">
        <div className="my-4 text-lg font-bold md:my-8 md:text-center md:text-2xl">
          <FormattedMessage id={translations.pages.refundStatus.status} />
        </div>

        <div className="grid md:grid-cols-4">
          {statuses.map((status, index) => (
            <RefundStatusItem
              key={status}
              {...{ status }}
              active={statusToDisplay === status}
              completed={index <= completedIndex}
            />
          ))}
        </div>

        <div className="flex flex-1 flex-col items-center justify-center space-y-6 px-10">
          {!!text && (
            <>
              <Alert
                type={statusToDisplay === RefundStatus.REJECTED ? 'error' : 'success'}
                title={title || refund?.refundErrorReason}
              >
                <p>{text}</p>
              </Alert>

              {!!url && (
                <Button appearance="primary" as={Link} to={url}>
                  {buttonText || 'Click here to resubmit'}
                </Button>
              )}
            </>
          )}

          <div className="flex flex-col items-center space-y-4">
            {!!season && (
              <p className="text-center text-sm md:text-base">
                <FormattedMessage id={translations.pages.refundStatus.seasonTicket} />
              </p>
            )}
          </div>
        </div>
      </div>
    </RefundLayout>
  );
};
