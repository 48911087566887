import { translations } from '@/locales';
import { PurchaseMethod } from '@repay/api-sdk';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

import { ProgressManager } from '@/hooks/useProgressManager';
import { useWarrants } from '@/hooks/useWarrants';

import { BackButton } from '@/components/BackButton';
import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { Select } from '@/components/Form/Select';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Step, StepFooter, StepHeader } from '@/components/Step';
import { SubmitButton } from '@/components/SubmitButton';

const schema = yup
  .object({
    warrantId: yup.string().label(translations.enum.purchaseMethod.warrant).required()
  })
  .required();

export const WarrantDetailsStep: React.FC = () => {
  const { back, updatePaymentDetails } = ProgressManager.useContainer();

  const { data: warrants = [] } = useWarrants();

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) =>
      updatePaymentDetails(PurchaseMethod.WARRANT, { id: values.warrantId }),
    [updatePaymentDetails]
  );

  return (
    <React.Fragment>
      <Step>
        <StepHeader title={<FormattedMessage id={translations.pages.progress.purchaseMethod.warrant.title} />} />

        <Form {...{ schema, onSubmit }}>
          {({ submitting }) => (
            <div className="space-y-4">
              <ValidatedField
                as={Select}
                field={InputWithLabel}
                items={warrants.map((warrant) => ({ name: warrant.accountName, value: warrant.id }))}
                id="warrantId"
                name="warrantId"
                searchable
                label={<FormattedMessage id={translations.enum.purchaseMethod.warrant} />}
                disabled={submitting}
                fieldClassName="md:w-2/3"
              />

              <StepFooter>
                <SubmitButton loading={submitting}>
                  <FormattedMessage id={translations.buttons.continue} />
                </SubmitButton>

                <BackButton onClick={() => back()} />
              </StepFooter>
            </div>
          )}
        </Form>
      </Step>
    </React.Fragment>
  );
};
