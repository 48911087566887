/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PurchaseMethod {
  COMPANY_CHEQUE = 'company_cheque',
  PLASTIC = 'plastic',
  CASH = 'cash',
  CHEQUE = 'cheque',
  WARRANT = 'warrant',
  CASHABLE_VOUCHER = 'cashable_voucher',
  BANK_TRANSFER = 'bank_transfer',
}
