/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Strike } from '../models/Strike';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class StrikeService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Returns active strikes list.
   * @returns Strike Active strikes found
   * @throws ApiError
   */
  public getActiveStrikes(): CancelablePromise<Array<Strike>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/strikes/active',
    });
  }

}
