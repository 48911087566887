import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ProgressManager } from '@/hooks/useProgressManager';
import { translations } from '@/locales';

import { Form } from '@/components/Form';
import { Step, StepFooter, StepHeader } from '@/components/Step';
import { SubmitButton } from '@/components/SubmitButton';

export const AgreementStep: React.FC = () => {
  const { onAgree: onSubmit } = ProgressManager.useContainer();

  return (
    <Step>
      <StepHeader title={<FormattedMessage id={translations.pages.progress.agreement.title} />} />

      <Form {...{ onSubmit }}>
        {() => (
          <React.Fragment>
            <div className="md:text-xl">
              <FormattedMessage
                id={translations.pages.progress.agreement.description}
                values={{
                  privacy: (children: React.ReactNode) => (
                    <a href="https://domain.com" target="_blank" rel="noopener noreferrer" className="underline">
                      {children}
                    </a>
                  )
                }}
              />
            </div>

            <StepFooter>
              <SubmitButton>
                <FormattedMessage id={translations.pages.progress.agreement.next} />
              </SubmitButton>
            </StepFooter>
          </React.Fragment>
        )}
      </Form>
    </Step>
  );
};
