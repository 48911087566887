import { BankDetails, PurchaseMethod } from '@repay/api-sdk';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ProgressManager } from '@/hooks/useProgressManager';
import { translations } from '@/locales';
import { apiClient } from '@/services/api-client';

import { Step, StepHeader } from '@/components/Step';

import { BankDetailsForm } from './BankDetailsForm';

export const BankDetailsStep: React.FC = () => {
  const { state, back: onBack, updatePaymentDetails } = ProgressManager.useContainer();

  const onSubmit = React.useCallback(
    async (values: BankDetails) => {
      await apiClient.paymentMethod.validateBankAccount({ requestBody: values });
      return updatePaymentDetails(PurchaseMethod.BANK_TRANSFER, values);
    },
    [updatePaymentDetails]
  );

  return (
    <React.Fragment>
      <Step>
        <StepHeader title={<FormattedMessage id={translations.pages.progress.purchaseMethod.bankTransfer.title} />} />

        <BankDetailsForm
          {...{
            initialValues: {
              fullName: [state.customer?.firstName, state.customer?.lastName].filter(Boolean).join(' '),
              ...state.payment?.[PurchaseMethod.BANK_TRANSFER]
            },
            onBack,
            onSubmit
          }}
        />
      </Step>
    </React.Fragment>
  );
};
