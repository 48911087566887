import { parsePhoneNumber } from 'awesome-phonenumber';
import * as yup from 'yup';

import { translations } from '@/locales';

function phone(this: yup.StringSchema, message?: string) {
  return this.test({
    name: 'phone',
    exclusive: false,
    message: message ?? translations.validation.mixed.default,
    test: (value) => !value || parsePhoneNumber(value).valid
  });
}

yup.addMethod<yup.StringSchema>(yup.string, 'phone', phone);
