import { BanknotesIcon, LightBulbIcon, TicketIcon } from '@heroicons/react/24/outline';
import {
  Booking,
  CardDetails,
  Customer,
  PurchaseLocation,
  PurchaseMethod,
  RefundReason,
  Ticket,
  TicketFormat,
  TicketImage,
  TicketType
} from '@repay/api-sdk';
import React from 'react';
import { createContainer } from 'unstated-next';

import { translations } from '@/locales';
import { areSameDates } from '@/utils/dateFunctions';

import { useStrikes } from './useStrikes';

interface NavigationGroup {
  Icon: typeof BanknotesIcon;
  name: string;
  active: boolean;
  steps: NavigationStep[];
}

interface NavigationStep {
  step: ProgressStep;
  active: boolean;
}

export interface RefundTicket extends Ticket {
  utn?: string;
  scn?: string;
  number?: string;
  reference?: string;
  railcardId?: number;
  image?: Partial<Record<TicketImage, string>>;
  reason?: Reason;
}

export interface Reason {
  reason: RefundReason;
  journeyDate?: string;
  journeyTime?: string;
  strikeActionDate?: string;
}

export interface BankDetails {
  fullName: string;
  accountNumber: string;
  sortCode: string;
}

export interface WarrantDetails {
  id: string;
}

export interface RefundState {
  step: ProgressStep;
  agreedAt?: Date;
  purchaseLocation?: PurchaseLocation;
  ticketFormat?: TicketFormat;
  ticketType?: TicketType;
  flexiUsedTickets?: number;
  selectableTickets: RefundTicket[];
  fulfillmentMethod?: string;
  tickets: RefundTicket[];
  booking?: Booking;
  customer?: Customer & { validated?: boolean };
  payment?: {
    method: PurchaseMethod;
    [PurchaseMethod.BANK_TRANSFER]?: BankDetails;
    [PurchaseMethod.PLASTIC]?: CardDetails;
    [PurchaseMethod.WARRANT]?: WarrantDetails;
  };
  canceled?: boolean;
}

export enum ProgressStep {
  Agreement = 'agreement',
  PurchaseLocation = 'purchaseLocation',
  SearchTicketsByBooking = 'searchTicketsByBooking',
  SearchTicketsBySmartcard = 'searchTicketsBySmartcard',
  TicketFormat = 'ticketFormat',
  TicketType = 'ticketType',
  TicketDetails = 'ticketDetails',
  TicketSelection = 'ticketSelection',
  RefundReason = 'refundReason',
  TicketImages = 'ticketImages',
  ContactDetails = 'contactDetails',
  CardDetails = 'cardDetails',
  WarrantDetails = 'warrantDetails',
  BankDetails = 'bankDetails',
  RefundSummary = 'refundSummary',
  OtherTicket = 'otherTicket'
}

export const mapPurchaseMethodToPaymentStep = (purchaseMethod: PurchaseMethod): ProgressStep => {
  switch (purchaseMethod) {
    case PurchaseMethod.CASH:
    case PurchaseMethod.CHEQUE:
    case PurchaseMethod.COMPANY_CHEQUE:
    case PurchaseMethod.CASHABLE_VOUCHER:
    case PurchaseMethod.BANK_TRANSFER:
      return ProgressStep.BankDetails;
    case PurchaseMethod.PLASTIC:
      return ProgressStep.CardDetails;
    case PurchaseMethod.WARRANT:
      return ProgressStep.WarrantDetails;
    default:
      throw new Error('Invalid purchase method');
  }
};
const useProgressManager = () => {
  const [states, setStates] = React.useState<RefundState[]>([]);
  const [state, setState] = React.useState<RefundState>({
    step: ProgressStep.Agreement,
    selectableTickets: [],
    tickets: []
  });

  // This will follow the ticket that is being edited
  const [ticketIndex, setTicketIndex] = React.useState<number>(0);
  const [ticketIndexes, setTicketIndexes] = React.useState<number[]>([]);

  React.useEffect(() => setTicketIndexes((indexes) => [...indexes, ticketIndex]), [ticketIndex, setTicketIndexes]);

  const { data: strikes = [] } = useStrikes();

  React.useEffect(() => {
    if (states.indexOf(state) >= 0) return;

    setStates((states) => [...states, state]);
  }, [states, setStates, state]);

  const back = React.useCallback(() => {
    const previousStates = states.slice();
    const previousState = previousStates[previousStates.length - 2];

    if (!previousState) return;

    setState((state) => ({ ...state, step: previousState.step }));

    setStates(previousStates.slice(0, previousStates.length - 2));
  }, [setState, states, setStates]);

  const ticketGroup = React.useMemo(() => {
    const steps = [
      { step: ProgressStep.PurchaseLocation, steps: [ProgressStep.Agreement, ProgressStep.OtherTicket] },
      { step: ProgressStep.TicketFormat, steps: [] },
      { step: ProgressStep.TicketType, steps: [] },
      {
        step: ProgressStep.TicketDetails,
        steps: [
          ProgressStep.SearchTicketsByBooking,
          ProgressStep.SearchTicketsBySmartcard,
          ProgressStep.TicketSelection
        ]
      },
      { step: ProgressStep.RefundReason, steps: [] },
      { step: ProgressStep.TicketImages, steps: [] }
    ];

    return { Icon: TicketIcon, name: translations.navigation.groups.ticket, steps };
  }, []);

  const customerGroup = React.useMemo(() => {
    const steps = [
      { step: ProgressStep.ContactDetails, steps: [] },
      {
        step: ProgressStep.BankDetails || ProgressStep.CardDetails || ProgressStep.WarrantDetails,
        steps: [ProgressStep.BankDetails, ProgressStep.CardDetails, ProgressStep.WarrantDetails]
      }
    ];

    return { Icon: BanknotesIcon, name: translations.navigation.groups.contact, steps };
  }, []);

  const reviewGroup = React.useMemo(() => {
    const steps = [{ step: ProgressStep.RefundSummary, steps: [] }];

    return { Icon: LightBulbIcon, name: translations.navigation.groups.review, steps };
  }, []);

  const groups = React.useMemo((): NavigationGroup[] => {
    return [ticketGroup, customerGroup, reviewGroup]
      .reverse()
      .reduce<NavigationGroup[]>((groups, group) => {
        const previousActive = groups.some((g) => g.active);
        const activeStepIndex = group.steps.findIndex(({ step, steps }) => [step, ...steps].includes(state.step));
        const steps = group.steps.map<NavigationStep>(({ step }, index) => ({
          step,
          active: previousActive || index <= activeStepIndex
        }));
        const active = steps.some((s) => s.active);

        groups.push({ ...group, active, steps });

        return groups;
      }, [])
      .reverse();
  }, [state.step, ticketGroup, customerGroup, reviewGroup]);

  return React.useMemo(
    () => ({
      state,
      states,
      groups,
      onAgree: () => setState((state) => ({ ...state, agreedAt: new Date(), step: ProgressStep.PurchaseLocation })),
      updatePurchaseLocation: (purchaseLocation: PurchaseLocation) =>
        setState((state) => ({
          ...state,
          purchaseLocation,
          step:
            purchaseLocation === PurchaseLocation.ONLINE
              ? ProgressStep.SearchTicketsByBooking
              : purchaseLocation === PurchaseLocation.STATION
              ? ProgressStep.TicketFormat
              : ProgressStep.OtherTicket
        })),
      updateTicketFormat: (ticketFormat: TicketFormat) =>
        setState((state) => ({
          ...state,
          ticketFormat,
          ...(ticketFormat === TicketFormat.PAPER
            ? { step: ProgressStep.TicketType }
            : ticketFormat === TicketFormat.SMARTCARD
            ? {
                step: ProgressStep.SearchTicketsBySmartcard,
                ticketType: TicketType.SINGLE
              }
            : {
                step: ProgressStep.TicketDetails,
                tickets: [{ purchaseLocation: state.purchaseLocation, format: ticketFormat } as RefundTicket]
              })
        })),
      updateTicketType: ({
        ticketType,
        ticketCount,
        railcardId
      }: {
        ticketType: TicketType;
        ticketCount: number;
        railcardId?: number;
      }) => {
        // In flexi flow, the stepper count will be assigned to flexiUsedTickets, and number of tickets will be assigned to 1
        const count = ticketType === TicketType.FLEXI ? 1 : ticketCount;

        setState((state) => ({
          ...state,
          ticketType,
          ...(ticketType === TicketType.FLEXI && { flexiUsedTickets: ticketCount }),
          tickets: (state.tickets ?? [])
            .slice(0, count)
            .concat(Array.from({ length: count - Math.max(state.tickets.length, 0) }).map(() => ({} as RefundTicket)))
            .map(
              (t) =>
                ({
                  ...t,
                  purchaseLocation: state.purchaseLocation,
                  format: state.ticketFormat,
                  type: ticketType,
                  purchaseMethod: state.payment?.method,
                  railcardId
                } as RefundTicket)
            ),
          step: ProgressStep.TicketDetails
        }));
      },
      updateSmartcardTicket: (scn: string) =>
        setState((state) => ({
          ...state,
          // TODO: Put the smartcard ticket selection back when we have an api that can search for smartcard tickets.
          // selectableTickets: tickets,
          // step: ProgressStep.TicketSelection
          tickets: [{ purchaseLocation: state.purchaseLocation, format: state.ticketFormat, scn } as RefundTicket],
          step: ProgressStep.TicketDetails
        })),
      updateBooking: (booking: Booking) =>
        setState((state) => ({
          ...state,
          selectableTickets: booking.tickets,
          booking,
          customer: booking.customer
            ? ({ email: booking.customer?.email, phone: booking.customer.phone } as Customer)
            : undefined,
          step: ProgressStep.TicketSelection
        })),
      selectTickets: (tickets: RefundTicket[]) =>
        setState((state) => ({
          ...state,
          tickets,
          payment: { method: tickets[0].purchaseMethod },
          step: ProgressStep.RefundReason
        })),
      updateTicketByIndex: (ticket: RefundTicket) => {
        // Edge case where the images should be skipped
        const skipImagesStep =
          ticket.purchaseLocation === PurchaseLocation.ONLINE || ticket.format === TicketFormat.SMARTCARD;

        const isLastTicket = ticketIndex === state.tickets.length - 1;

        const couldSkipReasonStep = [
          TicketType.ADVANCE,
          TicketType.RETURN,
          TicketType.DAY_RETURN,
          TicketType.DAY_TRAVELCARD,
          TicketType.SINGLE
        ].includes(ticket.type);

        // Edge case where the reason could be worked out from the date of travel (skipped)
        const skipReasonStep = strikes.some(
          (strike) => couldSkipReasonStep && areSameDates(strike.eventDate, ticket.dateOfTravel ?? ticket.validFrom)
        );

        const updatedTicket = skipReasonStep
          ? {
              ...ticket,
              reason: { reason: RefundReason.STRIKE_ACTION, strikeActionDate: ticket.dateOfTravel }
            }
          : ticket;

        setState((state) => ({
          ...state,
          tickets: [
            ...state.tickets.slice(0, ticketIndex),
            updatedTicket,
            ...state.tickets.slice(ticketIndex + 1, state.tickets.length)
          ],
          ...(ticketIndex === 0 && { payment: { method: ticket.purchaseMethod } }),
          step: skipReasonStep
            ? skipImagesStep
              ? isLastTicket
                ? ProgressStep.ContactDetails
                : ProgressStep.TicketDetails
              : ProgressStep.TicketImages
            : ProgressStep.RefundReason
        }));

        if (skipReasonStep && skipImagesStep) setTicketIndex((index) => index + 1);
      },
      updateRefundReason: (reason: Reason, stations: { origin?: string; destination?: string }) => {
        const skipImagesStep =
          state.purchaseLocation === PurchaseLocation.ONLINE || state.ticketFormat === TicketFormat.SMARTCARD;

        const isLastTicket = ticketIndex === state.tickets.length - 1;

        setState((state) => ({
          ...state,
          tickets: state.tickets.map((ticket, index) => ({
            ...ticket,
            reason: index === ticketIndex ? reason : ticket.reason,
            // Update stations ids from the group stations select, if applicable
            originStationId: index === ticketIndex && stations.origin ? stations.origin : ticket.originStationId,
            destinationStationId:
              index === ticketIndex && stations.destination ? stations.destination : ticket.destinationStationId
          })),
          step: skipImagesStep
            ? isLastTicket
              ? ProgressStep.ContactDetails
              : ProgressStep.RefundReason
            : ProgressStep.TicketImages
        }));

        if (skipImagesStep) setTicketIndex((index) => index + 1);
      },

      updateTicketImages: (image: Partial<Record<TicketImage, string>> & { imagesIds?: number[] }) => {
        const isLastTicket = ticketIndex === state.tickets.length - 1;

        setState((state) => ({
          ...state,
          tickets: state.tickets.map((ticket, index) => ({
            ...ticket,
            image: index === ticketIndex ? image : ticket.image
          })),
          step: isLastTicket ? ProgressStep.ContactDetails : ProgressStep.TicketDetails
        }));
        setTicketIndex((index) => index + 1);
      },
      updateCustomer: (customer: NonNullable<RefundState['customer']>) => {
        setState((state) => ({
          ...state,
          customer,
          step: mapPurchaseMethodToPaymentStep(state.payment!.method)
        }));
      },
      updatePaymentDetails: (method: PurchaseMethod, details?: BankDetails | WarrantDetails | CardDetails) => {
        setState((state) => ({
          ...state,
          step: ProgressStep.RefundSummary,
          payment: { method, [method]: details }
        }));
      },
      updateCanceled: (canceled?: boolean) => setState((state) => ({ ...state, canceled })),
      back,
      ticketIndex,
      setTicketIndex,
      previousTicketIndex: ticketIndexes[ticketIndexes.length - 2]
    }),
    [state, states, groups, back, ticketIndex, ticketIndexes, strikes]
  );
};

export const ProgressManager = createContainer(useProgressManager);
