import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import check from './check.svg';

export const RefundSentPage = () => (
  <div className="flex h-full flex-col">
    <header className="flex h-20 flex-shrink-0 flex-col items-center justify-center bg-[#003F2E] text-white" />

    <div className="flex flex-1 flex-col items-center justify-center px-10">
      <img src={check} className="my-4 w-12 md:mt-16 md:w-20" />

      <div className="flex flex-col items-center space-y-4">
        <h1 className="text-lg font-bold md:text-4xl">
          <FormattedMessage id={translations.pages.refundSent.title} />
        </h1>

        <p className="text-center text-sm md:text-base">
          <FormattedMessage id={translations.pages.refundStatus.track} />
        </p>
      </div>
    </div>
  </div>
);
