import { PencilIcon } from '@heroicons/react/24/solid';
import { Customer } from '@repay/api-sdk';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { BackButton } from '@/components/BackButton';
import { Button } from '@/components/Button';
import { Step, StepFooter, StepHeader } from '@/components/Step';
import { SubmitButton } from '@/components/SubmitButton';

interface Props {
  customer: Customer;
  onBack(): void;
  onSubmit(): void;
  onAction(): void;
}

export const CustomerDetailsStep: React.FC<Props> = ({ customer, onBack, onSubmit, onAction }) => (
  <Step>
    <StepHeader title={<FormattedMessage id={translations.pages.progress.customerDetails.details.title} />} />

    <div className="relative inline-flex min-w-fit max-w-lg flex-col rounded-md border border-green-800 p-6 sm:min-w-[300px]">
      <Button className="absolute right-4 top-4 w-fit px-0" onClick={onAction}>
        <PencilIcon stroke="currentColor" strokeWidth={3} className="h-6 text-[#86BC24]" />
      </Button>

      <div className="text-xl font-bold">
        {customer.firstName} {customer.lastName}
      </div>

      <div>{customer.email}</div>

      <div>{customer.phone}</div>

      <div>
        {[
          [customer.address.line1, customer.address.line2, customer.address.line3].filter(Boolean).join(' '),
          customer.address.city,
          customer.address.county,
          customer.address.country
        ].join(', ')}
      </div>
    </div>

    <StepFooter>
      <SubmitButton onClick={() => onSubmit()}>
        <FormattedMessage id={translations.buttons.continue} />
      </SubmitButton>

      <BackButton onClick={onBack} />
    </StepFooter>
  </Step>
);
