/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PurchaseLocation {
  STATION = 'station',
  ONLINE = 'online',
  OTHER = 'other',
}
