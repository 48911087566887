import useSWR from 'swr';

import { apiClient } from '@/services/api-client';

export const useStrikes = () => {
  return useSWR({ key: `getStrikes` }, () => apiClient.strike.getActiveStrikes(), {
    revalidateOnMount: true,
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });
};
