import { TicketType } from '@repay/api-sdk';

const seasonTypes = [
  TicketType.WEEKLY,
  TicketType.FLEXI,
  TicketType.MONTHLY,
  TicketType.MONTHLY_PLUS,
  TicketType.ANNUAL
];

export const isTicketTypeSeason = (type: TicketType) => {
  return seasonTypes.includes(type);
};
