import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import * as yup from 'yup';

import { translations } from '@/locales';

dayjs.extend(customParseFormat);

function date(this: yup.StringSchema, format = 'DD/MM/YYYY', message?: string) {
  return this.test({
    name: 'date',
    exclusive: false,
    message: message ?? translations.validation.mixed.default,
    params: { format },
    test: (value) => !value || dayjs(value, format, true).isValid()
  });
}

yup.addMethod<yup.StringSchema>(yup.string, 'date', date);
