import { TicketFormat } from '@repay/api-sdk';
import React from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

import { ProgressManager } from '@/hooks/useProgressManager';
import { translations } from '@/locales';

import { BackButton } from '@/components/BackButton';
import { Form } from '@/components/Form';
import { RadioSelect } from '@/components/Form/RadioSelect';
import { Step, StepFooter, StepHeader } from '@/components/Step';

import barcode from './barcode.svg';
import paper from './paper.svg';
import smartcard from './smartcard.svg';

const ticketFormats = [
  { src: barcode, value: TicketFormat.PAPER },
  { src: paper, value: TicketFormat.BARCODE },
  { src: smartcard, value: TicketFormat.SMARTCARD }
];

const schema = yup
  .object({ ticketFormat: yup.mixed<TicketFormat>().oneOf(Object.values(TicketFormat)).required() })
  .required();

export const TicketFormatStep: React.FC = () => {
  const { back, state, updateTicketFormat } = ProgressManager.useContainer();

  const onSubmit = React.useCallback(
    ({ ticketFormat }: yup.InferType<typeof schema>) => updateTicketFormat(ticketFormat),
    [updateTicketFormat]
  );

  return (
    <Step>
      <StepHeader title={<FormattedMessage id={translations.pages.progress.ticketFormat.title} />} />

      <Form {...{ initialValues: { ticketFormat: state.ticketFormat }, schema, onSubmit }}>
        {({ handleSubmit }) => (
          <React.Fragment>
            <div className="max-w-[320px] space-y-4">
              {ticketFormats.map(({ src, value }) => (
                <Field key={value} id={`ticket-format-${value}`} name="ticketFormat" type="radio" {...{ value }}>
                  {({ input, meta, ...props }) => (
                    <RadioSelect
                      {...props}
                      {...input}
                      contentClassName="flex items-center"
                      onClick={() => setTimeout(handleSubmit, 0)}
                    >
                      <img {...{ src }} className="mr-4 h-6 w-6" />
                      <FormattedMessage id={translations.enum.ticketFormat[value]} />
                    </RadioSelect>
                  )}
                </Field>
              ))}
            </div>

            <StepFooter>
              <BackButton onClick={() => back()} />
            </StepFooter>
          </React.Fragment>
        )}
      </Form>
    </Step>
  );
};
