/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TicketImage {
  TICKET = 'ticket',
  PHOTOCARD = 'photocard',
  DEFACED = 'defaced',
}
