/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImageUpload } from '../models/ImageUpload';
import type { Ticket } from '../models/Ticket';
import type { TicketImage } from '../models/TicketImage';
import type { TicketType } from '../models/TicketType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TicketService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Return a ticket.
   * @returns Ticket Ticket found
   * @throws ApiError
   */
  public getTicketByNumber({
    number,
    reference,
    type,
  }: {
    /**
     * Ticket number
     */
    number: string,
    /**
     * Ticket reference
     */
    reference: string,
    /**
     * Ticket type
     */
    type: TicketType,
  }): CancelablePromise<Ticket> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/tickets/number/{number}',
      path: {
        'number': number,
      },
      query: {
        'reference': reference,
        'type': type,
      },
    });
  }

  /**
   * Return a ticket.
   * @returns Ticket Ticket found
   * @throws ApiError
   */
  public getTicketByUtn({
    utn,
  }: {
    /**
     * Ticket unique ticket number
     */
    utn: string,
  }): CancelablePromise<Ticket> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/tickets/utn/{utn}',
      path: {
        'utn': utn,
      },
    });
  }

  /**
   * Return a ticket.
   * @returns Ticket Ticket found
   * @throws ApiError
   */
  public getTicketByScn({
    scn,
  }: {
    /**
     * Ticket smart card number
     */
    scn: string,
  }): CancelablePromise<Ticket> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/tickets/scn/{scn}',
      path: {
        'scn': scn,
      },
    });
  }

  /**
   * Return parameters to upload a ticket image.
   * @returns ImageUpload Parameters to upload the image to
   * @throws ApiError
   */
  public getTicketImageUrl({
    type,
    filename,
    claimId,
  }: {
    /**
     * Ticket image type
     */
    type: TicketImage,
    /**
     * The filename you want to upload
     */
    filename: string,
    /**
     * Claim identifier
     */
    claimId?: number,
  }): CancelablePromise<ImageUpload> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/tickets/image',
      query: {
        'type': type,
        'filename': filename,
        'claimId': claimId,
      },
    });
  }

}
