import { Transition } from '@headlessui/react';
import React from 'react';

export interface InstanceProps<T extends any[]> {
  onAction: OnAction<T>;
  onClose(): void;
}

export type Props<P extends InstanceProps<any[]>> = {
  notification: React.ComponentType<P>;
  isOpen: boolean;
} & P;

export interface OnAction<T extends any[]> {
  (...args: T): any;
}

export interface OpenNotification<P extends InstanceProps<any[]>> {
  (onAction: P['onAction']): void;
}

export const Notification = <P extends InstanceProps<any[]>>({
  notification: Component,
  isOpen,
  onAction,
  onClose,
  ...rest
}: Props<P>) => (
  <div aria-live="assertive" className="pointer-events-none fixed inset-0 flex items-start sm:p-6">
    <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
      <Transition
        show={isOpen}
        as={React.Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {/** @ts-ignore */}
        <Component {...rest} {...{ onAction, onClose }} />
      </Transition>
    </div>
  </div>
);
