/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AddressService } from './services/AddressService';
import { AuthenticationService } from './services/AuthenticationService';
import { BookingService } from './services/BookingService';
import { CardStorageService } from './services/CardStorageService';
import { CustomerService } from './services/CustomerService';
import { DiscountService } from './services/DiscountService';
import { PaymentMethodService } from './services/PaymentMethodService';
import { RefundService } from './services/RefundService';
import { StationService } from './services/StationService';
import { StrikeService } from './services/StrikeService';
import { TicketService } from './services/TicketService';
import { WarrantService } from './services/WarrantService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiClient {

  public readonly address: AddressService;
  public readonly authentication: AuthenticationService;
  public readonly booking: BookingService;
  public readonly cardStorage: CardStorageService;
  public readonly customer: CustomerService;
  public readonly discount: DiscountService;
  public readonly paymentMethod: PaymentMethodService;
  public readonly refund: RefundService;
  public readonly station: StationService;
  public readonly strike: StrikeService;
  public readonly ticket: TicketService;
  public readonly warrant: WarrantService;

  public readonly request: BaseHttpRequest;

  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? 'http://localhost:4000',
      VERSION: config?.VERSION ?? '1.0.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.address = new AddressService(this.request);
    this.authentication = new AuthenticationService(this.request);
    this.booking = new BookingService(this.request);
    this.cardStorage = new CardStorageService(this.request);
    this.customer = new CustomerService(this.request);
    this.discount = new DiscountService(this.request);
    this.paymentMethod = new PaymentMethodService(this.request);
    this.refund = new RefundService(this.request);
    this.station = new StationService(this.request);
    this.strike = new StrikeService(this.request);
    this.ticket = new TicketService(this.request);
    this.warrant = new WarrantService(this.request);
  }
}

