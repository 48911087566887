import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

import { translations } from '@/locales';

import { letterPattern } from '@/utils/patterns';

import { Alert } from '@/components/Alert';
import { BackButton } from '@/components/BackButton';
import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { createMaskFromRegex, MaskedInput } from '@/components/Form/MaskedInput';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { StepFooter } from '@/components/Step';
import { SubmitButton } from '@/components/SubmitButton';

interface Props {
  onBack?(): void;
  onSubmit(values: yup.InferType<typeof schema>): void;
  initialValues?: Partial<yup.InferType<typeof schema>>;
  className?: string;
  submitButtonMessage?: React.ReactNode;
}

export const schema = yup
  .object({
    fullName: yup.string().label(translations.fields.fullName).required(),
    accountNumber: yup.string().length(8).label(translations.fields.accountNumber).required(),
    sortCode: yup.string().label(translations.fields.sortCode).required()
  })
  .required();

export const BankDetailsForm: React.FC<Props> = ({
  onBack,
  onSubmit,
  initialValues,
  submitButtonMessage,
  className
}) => {
  return (
    <Form {...{ schema, onSubmit, initialValues, className }}>
      {({ submitting, submitError }) => (
        <div>
          <div className="space-y-4">
            <Alert type="info" title="Test instructions:">
              <div>
                Account number: <span className="font-medium">26207729</span>
              </div>
              <div>
                Sort code: <span className="font-medium">560029</span>
              </div>
            </Alert>

            {!!submitError && (
              <Alert type="error" title="Bank account details are invalid!">
                <FormattedMessage id={translations.pages.progress.purchaseMethod.bankTransfer.error} />
              </Alert>
            )}

            <div className="grid gap-4 md:grid-cols-2">
              <ValidatedField
                as={MaskedInput}
                field={InputWithLabel}
                mask={createMaskFromRegex(letterPattern)}
                id="full-name"
                name="fullName"
                type="text"
                label={<FormattedMessage id={translations.fields.fullName} />}
                disabled={submitting}
              />

              <ValidatedField
                field={InputWithLabel}
                as={MaskedInput}
                mask="00000000"
                id="account-number"
                name="accountNumber"
                type="text"
                label={<FormattedMessage id={translations.fields.accountNumber} />}
                disabled={submitting}
              />

              <ValidatedField
                field={InputWithLabel}
                as={MaskedInput}
                mask="00-00-00"
                id="sort-code"
                name="sortCode"
                type="text"
                label={<FormattedMessage id={translations.fields.sortCode} />}
                disabled={submitting}
              />
            </div>
          </div>

          <StepFooter>
            <SubmitButton loading={submitting}>
              {submitButtonMessage ?? <FormattedMessage id={translations.buttons.continue} />}
            </SubmitButton>

            {!!onBack && <BackButton onClick={onBack} />}
          </StepFooter>

          {!!submitError && (
            <Alert className="mt-4" type="error">
              {submitError?.body?.message ?? submitError?.message}
            </Alert>
          )}
        </div>
      )}
    </Form>
  );
};
