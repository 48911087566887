import * as React from 'react';
import { createNumberMask } from 'text-mask-addons';

import { getValueFromEvent, MaskedInput } from './MaskedInput';

type PotentialAmount = string | number | null | undefined;

export interface Props {
  value?: PotentialAmount;
  currency: string;
  onChange(value?: PotentialAmount): void;
}

export const CurrencyInput = React.forwardRef(function CurrencyInput(props: Props, ref: React.Ref<any>) {
  const mask = React.useMemo(
    () =>
      createNumberMask({
        prefix: props.currency,
        includeThousandsSeparator: true,
        allowDecimal: true
      }),
    [props.currency]
  );

  const value = React.useMemo(() => props.value?.toString() ?? '', [props.value]);

  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = getValueFromEvent(event);
      const normalizedValue = value.replace(/[^\d.]/g, '');
      const parsedValue = parseFloat(normalizedValue);

      return props.onChange?.(isNaN(parsedValue) ? value : parsedValue);
    },
    [props]
  );

  return <MaskedInput {...props} {...{ ref, value, mask, onChange }} />;
});
