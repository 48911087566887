import React from 'react';
import { FormattedMessage } from 'react-intl';
import { XMarkIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';

import { translations } from '@/locales';

interface Props {
  centered?: boolean;
  onClose?(): void;
}

export const ModalHeader: React.FC<React.PropsWithChildren<Props>> = ({ centered, children, onClose }) => (
  <header className="relative">
    <h1 className={classnames('text-lg font-bold', { 'text-center': centered, 'pr-10': !centered && onClose })}>
      {children}
    </h1>

    {!!onClose && (
      <button type="button" className="absolute top-1/2 right-0 -translate-y-1/2" onClick={onClose}>
        <span className="sr-only">
          <FormattedMessage id={translations.buttons.close} />
        </span>
        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
      </button>
    )}
  </header>
);
