import { TicketType } from '@repay/api-sdk';
import useSWR from 'swr';

import { apiClient } from '@/services/api-client';

export const useRailcards = ({ ticketType, all }: { ticketType?: TicketType; all?: boolean }) => {
  return useSWR(
    { key: `getRailcards`, ticketType },
    () => {
      if (!ticketType) return [];

      return apiClient.discount.getActiveRailcards({ ticketType, all });
    },
    {
      revalidateOnMount: true,
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );
};
