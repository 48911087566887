import React from 'react';
import { useForm } from 'react-final-form';

export const SubmitAutomaticallyWhenValid: React.FC = () => {
  const state = useForm();

  React.useEffect(() => {
    return state.subscribe(
      ({ valid, visited }) => {
        if (Object.keys(visited ?? {}).length === 0 || !valid) return;

        state.submit();
      },
      { valid: true, visited: true }
    );
  }, [state]);

  return null;
};
