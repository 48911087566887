import dayjs, { Dayjs } from 'dayjs';
import { FormatDateOptions, IntlShape } from 'react-intl';

import { DATE_FORMAT } from '@/components/Form/DateInput';

export const parseStringToDayJs = (date: Date | string, format: string = DATE_FORMAT): Dayjs => {
  if (dayjs(date, format, true).isValid()) return dayjs(date, format, true);

  return dayjs(date);
};

export const areSameDates = (date1: string | Date, date2: string | Date): boolean => {
  const parsedDate1 = parseStringToDayJs(date1);
  const parsedDate2 = parseStringToDayJs(date2);

  if (!parsedDate1.isValid() || !parsedDate2.isValid()) return false;

  return parsedDate1.isSame(parsedDate2);
};

export const isDateBefore = (date1: Date | string, date2: Date | string, daysLimit?: number): boolean => {
  const parsedDate1 = parseStringToDayJs(date1);
  const parsedDate2 = parseStringToDayJs(date2);

  if (!parsedDate1.isValid() || !parsedDate2.isValid()) return false;

  const isBefore = parsedDate1.isBefore(parsedDate2) || parsedDate1.isSame(parsedDate2);

  if (!isBefore && !parsedDate1.isSame(parsedDate2)) return false;

  if (daysLimit) {
    const differenceInDays = parsedDate2.diff(parsedDate1, 'days');
    return isBefore && differenceInDays <= daysLimit;
  }

  return true;
};

export const isDateAfterMaxMonths = (date1: string | Date, date2: string | Date, months = 1): boolean => {
  const parsedDate1 = parseStringToDayJs(date1);
  const parsedDate2 = parseStringToDayJs(date2);

  if (!parsedDate1.isValid() || !parsedDate2.isValid()) return false;

  if (parsedDate1.isSame(parsedDate2)) return true;

  if (months <= 0) return parsedDate1.isAfter(parsedDate2);

  const maxDate = parsedDate2.add(months, 'month');

  return parsedDate1.isAfter(parsedDate2) && parsedDate1.isBefore(maxDate);
};

export const intlFormatDate = (
  intl: IntlShape,
  date?: string | Date,
  options: FormatDateOptions = { year: 'numeric', month: 'long', day: 'numeric' }
) => intl.formatDate(date, options);
