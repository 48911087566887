import useSWR from 'swr';

import { apiClient } from '@/services/api-client';

export const useStations = (ids?: string[]) => {
  return useSWR({ key: `getStations`, ids }, ({ ids }) => apiClient.station.getStations({ ids }), {
    revalidateOnMount: true,
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });
};

export const useSellingStations = (ids?: string[]) => {
  return useSWR({ key: `getSellingStations`, ids }, ({ ids }) => apiClient.station.getSellingStations({ ids }), {
    revalidateOnMount: true,
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });
};
export const useGroupStations = (id: string) => {
  return useSWR({ key: `getGroupStations`, id }, ({ id }) => apiClient.station.getGroupStations({ id }), {
    revalidateOnMount: true,
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });
};
