import { CancelablePromise, OtpSendPayload, OtpVerificationPayload } from '@repay/api-sdk';

import { ApiRequestOptions } from '@repay/api-sdk/src/core/ApiRequestOptions';
import React from 'react';
import { useLocalStorage } from 'react-use';
import { createContainer } from 'unstated-next';

import { apiClient, updateAuthorization } from '@/services/api-client';

interface Session {
  accessToken: string;
}

const apiClientRequest = apiClient.request.request.bind(apiClient.request);

export const useAuth = () => {
  const [provisionalOtpData, setProvisionalOtpData] = React.useState<OtpSendPayload>();

  const [session, setSession, clearSession] = useLocalStorage<Session | undefined>('session', undefined, {
    deserializer: (value) => {
      try {
        const parsed = JSON.parse(value) as Partial<Session>;
        return parsed.accessToken ? (parsed as Session) : undefined;
      } catch {
        return undefined;
      }
    },
    raw: false,
    serializer: (session) => {
      if (!session) return JSON.stringify(session);

      return JSON.stringify({ accessToken: session.accessToken });
    }
  });

  updateAuthorization(session?.accessToken);

  apiClient.request.request = <T>(options: ApiRequestOptions): CancelablePromise<T> => {
    return new CancelablePromise<T>((resolve, reject, onCancel) => {
      const promise = apiClientRequest<T>(options);

      onCancel(promise.cancel);

      return promise
        .then((result) => resolve(result))
        .catch((error) => {
          // Clear session if token expired
          if (error?.status === 401) clearSession();

          reject(error);
          return;
        });
    });
  };

  const verifyOtp = async (requestBody: Omit<OtpVerificationPayload, 'email' | 'phone'>) => {
    const { accessToken, customer } = await apiClient.authentication.verifyOtp({
      requestBody: { ...requestBody, ...provisionalOtpData! }
    });

    updateAuthorization(accessToken);
    setSession({ accessToken });

    return { customer };
  };

  const sendOtp = async (requestBody: OtpSendPayload) => {
    setProvisionalOtpData(requestBody);
    return await apiClient.authentication.sendOtp({ requestBody });
  };

  const authenticated = !!session;

  return { authenticated, verifyOtp, sendOtp };
};

export const Auth = createContainer(useAuth);
