import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { ProgressManager } from '@/hooks/useProgressManager';

import { BackButton } from '@/components/BackButton';
import { Step, StepFooter, StepHeader } from '@/components/Step';

export const OtherTicketStep: React.FC = () => {
  const { back } = ProgressManager.useContainer();

  return (
    <Step>
      <StepHeader title={<FormattedMessage id={translations.pages.progress.otherTicket.title} />} />

      <div className="space-y-2">
        <div className="font-medium">
          <FormattedMessage id={translations.pages.progress.otherTicket.subtitle} />
        </div>

        <div>
          <FormattedMessage id={translations.pages.progress.otherTicket.message} />
        </div>
      </div>

      <StepFooter>
        <BackButton onClick={() => back()} />
      </StepFooter>
    </Step>
  );
};
