import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import React from 'react';

import { Button } from './Button';
import { LoadingIndicator } from './Loading';

interface Props {
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?(): void;
}

export const SubmitButton: React.FC<React.PropsWithChildren<Props>> = ({ loading, disabled, children, ...rest }) => (
  <Button as="button" type="submit" appearance="primary" {...rest} disabled={loading || disabled}>
    <span className="relative">
      <ArrowRightCircleIcon
        className={classnames('mr-2 h-6 w-6 transition-opacity', loading ? 'opacity-0' : 'opacity-100')}
      />
      {loading !== undefined && (
        <LoadingIndicator
          className={classnames(
            'absolute left-0.5 top-0.5 h-5 w-5 transition-opacity',
            loading ? 'opacity-100' : 'opacity-0'
          )}
        />
      )}
    </span>

    {children}
  </Button>
);
