import { arrow, autoUpdate, flip, offset, useFloating, useHover, useInteractions } from '@floating-ui/react';
import React from 'react';

interface Props {
  content: React.ReactNode;
  className?: string;
}

export const Tooltip: React.FC<React.PropsWithChildren<Props>> = ({ content, className, children }) => {
  const arrowRef = React.useRef(null);

  const [isOpen, setIsOpen] = React.useState(false);

  const floating = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    placement: 'top-end',
    strategy: 'absolute',
    middleware: [offset(4), flip(), arrow({ element: arrowRef, padding: 16 })]
  });

  const hover = useHover(floating.context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  return (
    <div {...{ className }}>
      <div ref={floating.refs.setReference} {...getReferenceProps()}>
        {children}
      </div>

      {isOpen && (
        <div
          ref={floating.refs.setFloating}
          style={{
            position: floating.strategy,
            top: floating.y ?? undefined,
            left: floating.x ?? undefined
          }}
          {...getFloatingProps()}
          className="z-10 rounded bg-[#F3F0E8] px-2 py-1 text-sm text-[#003F2E]"
        >
          {content}

          <div
            ref={arrowRef}
            style={{
              top: floating.middlewareData.arrow?.y ?? undefined,
              left: floating.middlewareData.arrow?.x ?? undefined
            }}
            className="absolute h-2 w-2 rotate-45 transform bg-inherit"
          />
        </div>
      )}
    </div>
  );
};
