import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { ProgressManager } from '@/hooks/useProgressManager';

import { Step, StepHeader } from '@/components/Step';

import { TicketImagesForm } from './TicketImagesForm';

export const maxFileSize = 10 * 1000 * 1000;

export const TicketImageStep: React.FC = () => {
  const { state, back, ticketIndex, updateTicketImages } = ProgressManager.useContainer();

  const ticket = state.tickets[ticketIndex];

  const count = state.tickets.length;

  return (
    <React.Fragment>
      <Step>
        <StepHeader
          title={
            <FormattedMessage
              id={translations.pages.progress.ticketImages.title}
              values={{ count, index: ticketIndex + 1 }}
            />
          }
        />

        <TicketImagesForm {...{ ticket }} onSubmit={updateTicketImages} onBack={back} />
      </Step>
    </React.Fragment>
  );
};
