import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ProgressManager } from '@/hooks/useProgressManager';
import { translations } from '@/locales';

interface StepProps {
  className?: string;
}

export const Step: React.FC<React.PropsWithChildren<StepProps>> = ({ className, children }) => {
  return (
    <div
      className={classnames(
        'flex min-h-screen w-full max-w-4xl flex-col bg-white p-4 text-[#003F2E] md:p-12',
        className
      )}
    >
      {children}
    </div>
  );
};

interface StepHeaderProps {
  step?: React.ReactNode;
  title: React.ReactNode;
}

export const StepHeader: React.FC<StepHeaderProps> = ({ title }) => {
  const { state, groups } = ProgressManager.useContainer();

  const group =
    groups
      .slice()
      .reverse()
      .find((group) => group.active) ?? groups[0];
  const count = group.steps.length;
  const index =
    group.steps.length -
      1 -
      group.steps
        .slice()
        .reverse()
        .findIndex((step) => step.active) ?? group.steps.length - 1;

  return (
    <div className="text-[#003F2E]">
      <p className="text-xl font-bold">
        <FormattedMessage
          id={translations.pages.progress.state}
          values={{ group: <FormattedMessage id={group?.name} />, index: index + 1, count }}
        />
      </p>
      <p>
        <FormattedMessage id={translations.enum.steps[state.step]} />
      </p>
      <StepTitle>{title}</StepTitle>
    </div>
  );
};

export const StepTitle: React.FC<React.PropsWithChildren> = ({ children }) => (
  <h1 className="pt-6 pb-4 text-xl font-bold md:py-8 md:text-4xl">{children}</h1>
);

export const StepFooterButtons: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div className="-m-2 flex flex-col items-stretch md:flex-row md:items-center md:justify-between">
    {React.Children.map(
      children as React.ReactElement,
      (child) => child && React.cloneElement(child, { className: classnames('m-2', child.props.className) })
    )}
  </div>
);

export const StepFooter: React.FC<React.PropsWithChildren> = (props) => (
  <footer className="mt-8">
    <StepFooterButtons {...props} />
  </footer>
);
