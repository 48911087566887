/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BankDetails } from '../models/BankDetails';
import type { CardDetails } from '../models/CardDetails';
import type { CreateRefundPayload } from '../models/CreateRefundPayload';
import type { Refund } from '../models/Refund';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RefundService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Creates a new refund.
   * @returns any Customer created.
   * @throws ApiError
   */
  public createRefund({
    requestBody,
  }: {
    requestBody: CreateRefundPayload,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/refunds/create',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Return a refund.
   * @returns any Refund found
   * @throws ApiError
   */
  public getRefund({
    claimRef,
    statusHash,
    updateHash,
  }: {
    /**
     * Claim reference, containing the claim toc, id and created date.
     */
    claimRef: string,
    /**
     * Unique long hash created for each refund when submitted
     */
    statusHash?: string,
    /**
     * Unique long hash created for each refund when an update is required.
     */
    updateHash?: string,
  }): CancelablePromise<(Refund & {
    id: number;
  })> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/refunds/id/{claimRef}',
      path: {
        'claimRef': claimRef,
      },
      query: {
        'statusHash': statusHash,
        'updateHash': updateHash,
      },
    });
  }

  /**
   * Update card details of a refund.
   * @returns any Refund card details updated.
   * @throws ApiError
   */
  public updateRefundCardDetails({
    id,
    requestBody,
  }: {
    /**
     * Refund id
     */
    id: number,
    requestBody: CardDetails,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/refunds/update/cardDetails/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update bank details of a refund.
   * @returns any Refund bank details updated.
   * @throws ApiError
   */
  public updateRefundBankDetails({
    id,
    requestBody,
  }: {
    /**
     * Refund id
     */
    id: number,
    requestBody: BankDetails,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/refunds/update/bankDetails/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
