import * as React from 'react';

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  multiple?: boolean;
  onChange: (files: FileList) => any;
}

export const FileUpload: React.FC<Props> = ({ className, children, onChange, ...rest }) => {
  const onChangeCallback = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.files!),
    [onChange]
  );

  return (
    <label htmlFor={rest.id} {...{ className }}>
      {children}

      <input {...rest} type="file" onChange={onChangeCallback} value="" className="block w-0 h-0 opacity-0 m-0" />
    </label>
  );
};
