import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ProgressManager } from '@/hooks/useProgressManager';
import { translations } from '@/locales';

export const Navigation: React.FC = () => {
  const { groups } = ProgressManager.useContainer();

  const index = groups.findIndex((g) => g.active);

  return (
    <nav className="relative flex h-16 items-center bg-[#003F2E] px-4 text-[#F3F0E8] md:h-auto md:w-[350px] md:items-start md:p-4 lg:min-h-screen lg:p-12">
      <div className="absolute top-1/2 left-8 right-8 h-[1px] bg-white md:hidden">
        <div
          className="absolute inset-0 origin-left bg-[#86BC24] transition-transform"
          style={{ transform: `scaleX(${index / (groups.length - 1)})` }}
        />
      </div>

      <ul className="relative flex flex-1 justify-between md:flex-col md:justify-start md:space-y-10">
        {groups.map(({ Icon, name, active, steps }) => (
          <li key={name}>
            <div className="flex items-center space-x-3 md:mb-4">
              {
                <Icon
                  className={classnames(
                    'h-8 w-8 flex-shrink-0 rounded-full p-2 ring-8 ring-[#003F2E]',
                    active ? 'bg-[#86BC24]' : 'border border-[#F3F0E8] bg-[#003F2E]'
                  )}
                />
              }
              <p className="hidden text-[20px] font-semibold md:block">
                <FormattedMessage id={name} />
              </p>
            </div>

            <ul className="hidden space-y-2 md:block">
              {steps.map(({ step, active }) => (
                <li key={step}>
                  <div className="flex items-center space-x-3">
                    {
                      <div
                        className={classnames(
                          'mx-3 h-2 w-2 flex-shrink-0 rounded-full',
                          active ? 'bg-[#86BC24]' : 'ring-1 ring-[#F3F0E8]'
                        )}
                      />
                    }
                    <p className="">
                      <FormattedMessage id={translations.enum.steps[step]} />
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </nav>
  );
};
