import {
  getAsYouType,
  getExample,
  getRegionCodeForCountryCode,
  getSupportedCallingCodes,
  parsePhoneNumber
} from 'awesome-phonenumber';
import classnames from 'classnames';
import React from 'react';
import TextMaskInput from 'react-text-mask';

import countries from './countries.json';
import { getFlagEmoji } from './get-flag-emoji';

import { getInputReadOnlyClassNames, Input } from '@/components/Form/Input';
import { MaskedInput } from '@/components/Form/MaskedInput';
import { Select } from '@/components/Form/Select';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface CountryPhonePrefix {
  regionCode: string;
  country: string;
  flag: string;
  prefix: number;
}

const generateMaskFromFormat = (format: string) => {
  const letterPattern = /\d/;

  /**
   * make first 0 literal intead of any digit so it's prefilled
   * example:
   * - placeholder = 0712 034 567
   * - patterns = ['0', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]
   */
  return format.split('').reduce(
    (data, key) => {
      const matched = letterPattern.test(key);
      const pattern = !matched || (key === '0' && !data.matched) ? key : /\d/;

      return {
        patterns: [...data.patterns, pattern],
        matched: matched || data.matched
      };
    },
    { patterns: [] as (RegExp | string)[], matched: false }
  ).patterns;
};

export const PhoneInput: React.FC<React.ComponentProps<typeof Input>> = ({ value, className, onChange, ...props }) => {
  const { items, prefixByRegionCode } = React.useMemo(() => {
    const callingCodes = getSupportedCallingCodes() as unknown as number[];

    const items = callingCodes
      .map((callingCode) => getRegionCodeForCountryCode(callingCode))
      .reduce<CountryPhonePrefix[]>((items, regionCode, index) => {
        const country = countries[regionCode as keyof typeof countries];
        if (!country) return items;

        const prefix = callingCodes[index];
        if (!prefix) return items;

        const flag = getFlagEmoji(regionCode);

        return [...items, { country, regionCode, prefix, flag }];
      }, [])
      .sort((a, b) => a.country.localeCompare(b.country));

    const prefixByRegionCode = new Map(items.map((item) => [item.regionCode, item.prefix]));

    return { items, prefixByRegionCode };
  }, []);

  const { regionCode, number } = React.useMemo(() => {
    const pn = parsePhoneNumber(value || '+44');

    const regionCode = pn.regionCode;
    const number = pn.number?.national || null;
    const prefix = regionCode && prefixByRegionCode.get(regionCode);

    return { regionCode, number, prefix };
  }, [value, prefixByRegionCode]);

  const placeholder = React.useMemo(
    () => (regionCode ? getExample(regionCode, 'mobile').number?.national ?? '' : ''),
    [regionCode]
  );

  const mask = generateMaskFromFormat(placeholder);

  const ref = React.useRef<TextMaskInput>(null);

  const onChangeCountry = React.useCallback(
    (regionCode: string) => {
      const prefix = prefixByRegionCode.get(regionCode);
      onChange(`+${prefix}`);
      ref.current?.inputElement?.focus();
    },
    [prefixByRegionCode, onChange]
  );

  const onChangeNumber = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const prefix = regionCode && prefixByRegionCode.get(regionCode);

      if (!regionCode || !prefix) return value;

      const ayt = getAsYouType(regionCode);
      ayt.addChar(value);
      const phone = ayt.getPhoneNumber();

      const prefixedValue = phone.valid ? phone.number.international : `+${prefix}${value}`;

      onChange(prefixedValue);
    },
    [regionCode, prefixByRegionCode, onChange]
  );

  return (
    <Input
      as="div"
      className={classnames(
        'relative flex',
        className?.indexOf('border-red') >= 0 && 'border-red-500',
        getInputReadOnlyClassNames(props.readOnly || props.disabled)
      )}
    >
      <Select<string, any>
        {...props}
        id={`${props.id}-country`}
        name={`${props.name}Country`}
        type="text"
        value={regionCode}
        {...{ items }}
        className="h-full w-full truncate rounded-l px-4 pt-5 pb-0"
        containerClassName="w-64"
        searchable
        getValue={(item: CountryPhonePrefix) => item.regionCode}
        getDisplayName={(item: CountryPhonePrefix) =>
          item ? `${item.flag}\xa0\xa0${item.country} (+${item.prefix})` : ``
        }
        getSelectedDisplayName={({ selectedItems }) => (selectedItems[0] ? `+${selectedItems[0].prefix}` : '')}
        getSearchableAttributes={(item: CountryPhonePrefix) => `${item.country} ${item.regionCode} (+${item.prefix})`}
        onChange={onChangeCountry}
        readOnly={props.readOnly}
        disabled={props.disabled}
      />

      <div className="my-3 h-6 w-[1px] flex-shrink-0 bg-[#DBDBDB]" />

      <MaskedInput
        {...props}
        {...{ ref, mask, placeholder }}
        value={number}
        className="h-full w-full basis-full rounded-r bg-inherit pl-4 pr-11"
        onChange={onChangeNumber}
        readOnly={props.readOnly}
        disabled={props.disabled}
      />

      {value && (
        <button type="button" className="absolute right-1 top-1.5 p-2" onClick={() => onChange()}>
          <XMarkIcon className="h-5 w-5" />
        </button>
      )}
    </Input>
  );
};
