import { ArrowLongLeftIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { Button } from './Button';

interface Props {
  className?: string;
  onClick(): void;
}

export const BackButton: React.FC<React.PropsWithChildren<Props>> = ({ className, ...props }) => (
  <Button as="button" type="button" appearance="secondary" {...props} className={classnames('md:-order-1', className)}>
    <ArrowLongLeftIcon className="mr-2 h-6 w-6" />

    <FormattedMessage id={translations.buttons.back} />
  </Button>
);
