import React from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import 'tailwindcss/tailwind.css';

import { Language } from '@/constants';
import { getTranslationMessages } from '@/locales';

import '@/utils/yup-date';
import '@/utils/yup-phone';

import { App } from './App';

const root = createRoot(document.getElementById('app')!);

root.render(
  <IntlProvider
    locale={Language.English}
    messages={getTranslationMessages(Language.English)}
    defaultRichTextElements={{ br: (<br />) as any }}
    fallbackOnEmptyString={false}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </IntlProvider>
);
