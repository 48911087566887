/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WarrantAccount } from '../models/WarrantAccount';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WarrantService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Returns warrants list.
   * @returns WarrantAccount Warrants found
   * @throws ApiError
   */
  public getWarrantAccounts(): CancelablePromise<Array<WarrantAccount>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/warrants',
    });
  }

}
