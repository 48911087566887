import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { ProgressManager } from '@/hooks/useProgressManager';

import { StepTitle } from '@/components/Step';
import { BackButton } from '@/components/BackButton';

export const CanceledStep: React.FC = () => {
  const { back } = ProgressManager.useContainer();

  return (
    <div className="fixed inset-0 flex flex-col justify-center p-10 bg-white">
      <div className="max-w-3xl mx-auto flex flex-col items-center justify-center text-center">
        <StepTitle>
          <FormattedMessage id={translations.pages.progress.refundCanceled.title} />
        </StepTitle>

        <div className="text-sm md:text-base">
          <FormattedMessage id={translations.pages.progress.refundCanceled.message} />
        </div>

        <div className="mt-10">
          <BackButton onClick={back} />
        </div>
      </div>
    </div>
  );
};
