/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GroupStationResponse } from '../models/GroupStationResponse';
import type { Station } from '../models/Station';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class StationService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Returns origin and destination stations.
   * @returns Station Stations found
   * @throws ApiError
   */
  public getStations({
    ids,
  }: {
    /**
     * Optionally filters stations by id
     */
    ids?: Array<string>,
  }): CancelablePromise<Array<Station>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/stations',
      query: {
        'ids': ids,
      },
    });
  }

  /**
   * Returns selling stations.
   * @returns Station Stations found
   * @throws ApiError
   */
  public getSellingStations({
    ids,
  }: {
    /**
     * Optionally filters stations by id
     */
    ids?: Array<string>,
  }): CancelablePromise<Array<Station>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/stations/selling',
      query: {
        'ids': ids,
      },
    });
  }

  /**
   * Returns the stations of the stations group.
   * @returns GroupStationResponse The stations inside the group.
   * @throws ApiError
   */
  public getGroupStations({
    id,
  }: {
    /**
     * The id (nlc) of the station
     */
    id: string,
  }): CancelablePromise<GroupStationResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/stations/group/{id}',
      path: {
        'id': id,
      },
    });
  }

}
