/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressSearchResult } from '../models/AddressSearchResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AddressService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Returns address autocomplete options.
   * @returns AddressSearchResult An array of address items
   * @throws ApiError
   */
  public autocompleteAddress({
    query,
  }: {
    /**
     * The address query that you will get autocomplete results.
     */
    query: string,
  }): CancelablePromise<Array<AddressSearchResult>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/addresses/autocomplete',
      query: {
        'query': query,
      },
    });
  }

}
