/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Customer } from '../models/Customer';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CustomerService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Update a customer's information.
   * @returns void
   * @throws ApiError
   */
  public updateCustomer({
    requestBody,
  }: {
    requestBody: Customer,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/customer',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
