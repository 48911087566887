/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Railcard } from '../models/Railcard';
import type { TicketType } from '../models/TicketType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DiscountService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Returns active railcards.
   * @returns Railcard Active railcards found
   * @throws ApiError
   */
  public getActiveRailcards({
    ticketType,
    all,
  }: {
    /**
     * The ticket type for wich the railcards apply.
     */
    ticketType: TicketType,
    /**
     * A flag to return all railcards, or only the group save and two together.
     */
    all?: boolean,
  }): CancelablePromise<Array<Railcard>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/discounts/railcards',
      query: {
        'ticketType': ticketType,
        'all': all,
      },
    });
  }

}
