import { Customer } from '@repay/api-sdk';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { Step, StepHeader } from '@/components/Step';

import { LoginForm } from './LoginForm';

interface Props {
  customer?: Pick<Customer, 'email'>;
  onBack(): void;
  onSubmit(userIdentifier: { email: string }): Promise<void>;
}

export const LoginStep: React.FC<Props> = ({ customer, onBack, onSubmit }) => (
  <Step>
    <StepHeader title={<FormattedMessage id={translations.pages.progress.customerDetails.login.title} />} />

    <LoginForm className="min-w-fit md:w-3/4" {...{ onBack, onSubmit, customer }} />
  </Step>
);
