import { RefundReason, TicketImage } from '@repay/api-sdk';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { InstanceProps, ModalHeader, ModalLayout } from '@/components/Modal';

import defacedDisruption from '@/assets/cards/ticket-defaced-disruption.svg';
import defacedStrike from '@/assets/cards/ticket-defaced-strike.svg';
import defacedUnused from '@/assets/cards/ticket-defaced-unused.svg';
import defaced from '@/assets/cards/ticket-defaced.svg';
import photocard from '@/assets/cards/ticket-photocard.svg';

export interface Props
  extends InstanceProps<[], { imageType: TicketImage; count: number; refundReason?: RefundReason }> {}

export const TicketImageHelpModal: React.FC<Props> = ({ data, onClose }) => (
  <ModalLayout>
    <ModalHeader centered {...{ onClose }}>
      <FormattedMessage
        id={translations.pages.progress.ticketImages.add[data.imageType]}
        values={{ count: data.count }}
      />
    </ModalHeader>

    <div className="grid gap-4 pt-4">
      {[
        data.imageType === TicketImage.TICKET && defaced,
        data.imageType === TicketImage.DEFACED && !data.refundReason && defaced,
        data.imageType === TicketImage.DEFACED && data.refundReason === RefundReason.UNUSED && defacedUnused,
        data.imageType === TicketImage.DEFACED && data.refundReason === RefundReason.DISRUPTION && defacedDisruption,
        data.imageType === TicketImage.DEFACED && data.refundReason === RefundReason.STRIKE_ACTION && defacedStrike,
        data.imageType === TicketImage.PHOTOCARD && photocard
      ]
        .filter((item): item is string => !!item)
        .map((src) => (
          <img key={src!} {...{ src }} alt="" />
        ))}
    </div>
  </ModalLayout>
);
