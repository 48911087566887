import { BookingTicket, PurchaseMethod } from '@repay/api-sdk';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';

import { translations } from '@/locales';
import { apiClient } from '@/services/api-client';
import { parseStringToDayJs } from '@/utils/dateFunctions';

import { ProgressManager } from '@/hooks/useProgressManager';
import { useStations } from '@/hooks/useStations';

import { Alert } from '@/components/Alert';
import { BackButton } from '@/components/BackButton';
import { Form } from '@/components/Form';
import { Step, StepFooter, StepHeader } from '@/components/Step';
import { SubmitButton } from '@/components/SubmitButton';

export const RefundSummaryStep: React.FC = () => {
  const navigate = useNavigate();

  const { state, back } = ProgressManager.useContainer();

  const onSubmit = async () => {
    const promises: Array<Promise<void>> = [];

    state.tickets.forEach((ticket) => {
      promises.push(
        apiClient.refund.createRefund({
          requestBody: {
            ...ticket,
            reason: ticket.reason!.reason,
            journeyDate: ticket.reason?.journeyDate,
            journeyTime: ticket.reason?.journeyTime,
            bookingReference: state.booking?.reference,
            payment: state.payment,
            otrTicketId: (ticket as BookingTicket)?.id ? Number((ticket as BookingTicket)?.id) : undefined,
            flexiUsedTickets: state.flexiUsedTickets
          }
        })
      );
    });
    await Promise.all(promises);

    navigate('/sent');
  };

  const stationIds = React.useMemo(
    () => Array.from(new Set(state.tickets.map((t) => [t.originStationId, t.destinationStationId]).flat())),
    [state.selectableTickets]
  );

  const { data: stations = [] } = useStations(stationIds);

  return (
    <Step>
      <StepHeader title={<FormattedMessage id={translations.pages.progress.refundSummary.title} />} />

      <Form {...{ onSubmit }}>
        {({ submitting, submitError }) => (
          <React.Fragment>
            <div className="grid max-w-4xl text-sm md:grid-cols-2 md:gap-16">
              <div className="space-y-1 py-4">
                <label className="font-bold">
                  <FormattedMessage id={translations.enum.steps.contactDetails} />
                </label>

                <div>
                  <div>{state.customer?.email}</div>

                  <div>{state.customer?.phone}</div>

                  <div>
                    {[
                      [state.customer?.address?.line1, state.customer?.address?.line2, state.customer?.address?.line3]
                        .filter(Boolean)
                        .join(' '),
                      state.customer?.address?.city,
                      state.customer?.address?.county,
                      state.customer?.address?.country
                    ]
                      .filter(Boolean)
                      .join(', ')}
                  </div>
                </div>
              </div>

              <div className="space-y-1 py-4">
                <label className="font-bold">
                  <FormattedMessage id={translations.enum.steps.purchaseMethod} />
                </label>

                <div>
                  {state.payment?.method && (
                    <div>
                      <FormattedMessage id={translations.enum.purchaseMethod[state.payment.method]} />
                    </div>
                  )}

                  {state.payment?.method === PurchaseMethod.BANK_TRANSFER && (
                    <React.Fragment>
                      <div>{state.payment?.[PurchaseMethod.BANK_TRANSFER]?.fullName}</div>

                      <div>
                        {state.payment?.[PurchaseMethod.BANK_TRANSFER]?.accountNumber} /{' '}
                        {state.payment?.[PurchaseMethod.BANK_TRANSFER]?.sortCode}
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>

            <div className="-mx-3 overflow-x-auto">
              <div className="inline-block min-w-full px-3 align-middle">
                <table className="min-w-full divide-y divide-[#F3F0E8] text-sm">
                  <thead className="text-left font-semibold text-[#003F2E]">
                    <tr>
                      <th scope="col" className="whitespace-nowrap py-4 pr-3 align-bottom">
                        <FormattedMessage id={translations.fields.ticketNumber} />
                      </th>

                      <th scope="col" className="whitespace-nowrap py-4 px-3 align-bottom">
                        <FormattedMessage id={translations.fields.ticketFormat} />
                      </th>

                      <th scope="col" className="whitespace-nowrap py-4 px-3 align-bottom">
                        <FormattedMessage id={translations.fields.route} />
                      </th>

                      <th scope="col" className="whitespace-nowrap py-4 px-3 align-bottom">
                        <FormattedMessage id={translations.fields.validBetween} />
                      </th>

                      <th scope="col" className="whitespace-nowrap py-4 pl-3 align-bottom">
                        <FormattedMessage id={translations.fields.refundReason} />
                      </th>
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-[#F3F0E8] text-sm">
                    {state.tickets.map((ticket, index) => (
                      <tr key={index}>
                        <td className="whitespace-nowrap py-4 pr-3">
                          <div>
                            <FormattedMessage id={translations.enum.ticketFormat[ticket.format]} />
                            {` - `}
                            <FormattedMessage id={translations.enum.purchaseLocation[ticket.purchaseLocation]} />
                          </div>

                          <div>{ticket.number}</div>
                        </td>

                        <td className="whitespace-nowrap py-4 px-3">
                          {`1 x `}
                          <FormattedMessage id={translations.enum.ticketType[ticket.type]} />
                        </td>

                        <td className="whitespace-nowrap py-4 px-3">
                          <div>{stations.find((item) => item.id === ticket.originStationId)?.name}</div>
                          <div>{stations.find((item) => item.id === ticket.destinationStationId)?.name}</div>
                        </td>

                        <td className="whitespace-nowrap py-4 px-3">
                          <div>
                            <FormattedDate
                              value={parseStringToDayJs(ticket.validFrom).toDate()}
                              year="numeric"
                              month="short"
                              day="2-digit"
                            />
                          </div>

                          <div>
                            <FormattedDate
                              value={parseStringToDayJs(ticket.validTo).toDate()}
                              year="numeric"
                              month="short"
                              day="2-digit"
                            />
                          </div>
                        </td>

                        <td className="whitespace-nowrap py-4 pl-3">
                          <FormattedMessage id={translations.enum.refundReason[ticket.reason!.reason]} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <StepFooter>
              <SubmitButton loading={submitting}>
                <FormattedMessage id={translations.pages.progress.refundSummary.next} />
              </SubmitButton>

              <BackButton onClick={back} />
            </StepFooter>

            {!!submitError && (
              <Alert className="mt-4" type="error" title={submitError?.body?.message ?? submitError?.message} />
            )}
          </React.Fragment>
        )}
      </Form>
    </Step>
  );
};
