import React from 'react';
import classnames from 'classnames';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  contentClassName?: string;
}

export const RadioSelect = React.forwardRef(
  ({ className, contentClassName, children, ...rest }: React.PropsWithChildren<Props>, ref: React.Ref<any>) => (
    <label
      htmlFor={rest.id}
      className={classnames(
        'flex items-center py-3 px-4 h-12 border border-solid rounded select-none',
        rest.checked ? 'bg-[#86BC24] border-[#86BC24]' : 'border-[#003F2E4D] hover:bg-gray-50',
        rest.disabled ? 'cursor-default text-[#809F97]' : 'cursor-pointer',
        className
      )}
    >
      <input {...{ ref: ref as any }} {...rest} className="block w-0 h-0 opacity-0 m-0" />

      <div className={contentClassName}>{children}</div>
    </label>
  )
);
