import { TicketType } from '@repay/api-sdk';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useModal } from '@/hooks/useModal';
import { ProgressManager } from '@/hooks/useProgressManager';
import { translations } from '@/locales';

import { BackButton } from '@/components/BackButton';
import { RadioSelect } from '@/components/Form/RadioSelect';
import { Modal } from '@/components/Modal';
import { Step, StepFooter, StepHeader } from '@/components/Step';

import { TicketCountModal } from './TicketCountModal';

import ticket from '@/assets/icons/ticket.svg';

const dayTicketTypes = [
  { src: ticket, type: TicketType.ADVANCE },
  { src: ticket, type: TicketType.SINGLE },
  { src: ticket, type: TicketType.RETURN },
  { src: ticket, type: TicketType.RETURN_PARTIAL },
  { src: ticket, type: TicketType.DAY_RETURN },
  { src: ticket, type: TicketType.DAY_RETURN_PARTIAL },
  { src: ticket, type: TicketType.DAY_TRAVELCARD },
  { src: ticket, type: TicketType.CARNET }
];

const seasonTicketTypes = [
  { src: ticket, type: TicketType.WEEKLY },
  { src: ticket, type: TicketType.FLEXI },
  { src: ticket, type: TicketType.RANGER },
  { src: ticket, type: TicketType.ROVER },
  { src: ticket, type: TicketType.MONTHLY },
  { src: ticket, type: TicketType.MONTHLY_PLUS },
  { src: ticket, type: TicketType.ANNUAL }
];

export const TicketTypeStep: React.FC = () => {
  const { back, state, updateTicketType } = ProgressManager.useContainer();
  const [ticketType, setTicketType] = React.useState(state.ticketType);

  const handleRadioClick = (type: TicketType) => {
    // Locally update the checked radio
    setTicketType(type);

    modal.open(updateTicketType, { ticketType: type });
  };

  const modal = useModal(TicketCountModal);

  return (
    <Step>
      <StepHeader title={<FormattedMessage id={translations.pages.progress.ticketType.title} />} />

      <div className="grid max-w-4xl gap-8 md:grid-cols-2 md:gap-16">
        <div className="space-y-4">
          <div className="font-bold">
            <FormattedMessage id={translations.pages.progress.ticketType.dayTickets} />
          </div>

          {dayTicketTypes.map(({ src, type }) => (
            <RadioSelect
              key={type}
              id={`ticket-type-${type}`}
              name="ticketType"
              type="radio"
              value={type}
              checked={ticketType === type}
              readOnly
              contentClassName="flex items-center"
              onClick={() => handleRadioClick(type)}
            >
              <img {...{ src }} className="mr-4 h-5 w-5" />
              <FormattedMessage id={translations.enum.ticketType[type]} />
            </RadioSelect>
          ))}
        </div>

        <div className="space-y-4">
          <div className="font-bold">
            <FormattedMessage id={translations.pages.progress.ticketType.seasonTickets} />
          </div>

          {seasonTicketTypes.map(({ src, type }) => (
            <RadioSelect
              key={type}
              id={`ticket-type-${type}`}
              name="ticketType"
              type="radio"
              value={type}
              checked={ticketType === type}
              readOnly
              contentClassName="flex items-center"
              onClick={() => handleRadioClick(type)}
            >
              <img {...{ src }} className="mr-4 h-5 w-5" />
              <FormattedMessage id={translations.enum.ticketType[type]} />
            </RadioSelect>
          ))}
        </div>
      </div>

      <StepFooter>
        <BackButton onClick={() => back()} />
      </StepFooter>

      <Modal {...modal.props} />
    </Step>
  );
};
