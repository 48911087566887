import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { InstanceProps, ModalHeader, ModalLayout } from '@/components/Modal';

import barcode1 from '@/assets/cards/ticket-barcode-1.svg';
import barcode2 from '@/assets/cards/ticket-barcode-2.svg';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';

export interface Props extends InstanceProps<[], any> {}

const assets = [barcode1, barcode2];

export const TicketBarcodeHelpModal: React.FC<Props> = ({ onClose }) => {
  const [index, setIndex] = React.useState(0);

  return (
    <ModalLayout>
      <ModalHeader centered {...{ onClose }}>
        <FormattedMessage id={translations.fields.utn} />
      </ModalHeader>

      <div className="-mx-2 flex items-center">
        <button
          type="button"
          className="rounded p-2 hover:bg-gray-100"
          onClick={() => setIndex((index) => (index + assets.length - 1) % assets.length)}
        >
          <ArrowLeftIcon className="h-6 w-6" />
        </button>

        <div className="p-4">
          <img src={assets[index]} alt="" />
        </div>

        <button
          type="button"
          className="rounded p-2 hover:bg-gray-100"
          onClick={() => setIndex((index) => (index + assets.length + 1) % assets.length)}
        >
          <ArrowRightIcon className="h-6 w-6" />
        </button>
      </div>
    </ModalLayout>
  );
};
