import classnames from 'classnames';
import React from 'react';

import { As, AsComponent, AsProps } from './As';

export type Appearance = 'primary' | 'secondary';

const styles: Record<Appearance, string> = {
  primary:
    'bg-[#86BC24] text-[#003F2E] leading-12 h-12 rounded hover:bg-opacity-95 disabled:bg-[#F3F0E8] disabled:text-gray-500',
  secondary: 'bg-gray-50 text-[#003F2E] leading-12 h-12 rounded hover:bg-gray-100'
};

interface Props {
  appearance?: Appearance;
  wide?: boolean;
}

export const Button = React.forwardRef(
  <T extends AsComponent>(
    {
      // @ts-ignore
      as = 'button',
      appearance,
      wide,
      disabled,
      className,
      children,
      onClick,
      ...rest
    }: Props & AsProps<T>,
    ref: React.Ref<HTMLElement>
  ) => (
    <As
      {...{ ref, as, disabled }}
      className={classnames(
        'flex cursor-pointer items-center justify-center whitespace-nowrap font-medium transition disabled:cursor-default disabled:bg-opacity-90',
        wide ? 'px-10' : wide !== false ? 'px-4' : undefined,
        !!appearance && styles[appearance],
        className
      )}
      onClick={disabled ? null : onClick}
      {...rest}
    >
      {children}
    </As>
  )
);
