import { Railcard, TicketType } from '@repay/api-sdk';
import React from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

import { translations } from '@/locales';

import { Button } from '@/components/Button';
import { CloseButton } from '@/components/CloseButton';
import { Form } from '@/components/Form';
import { RadioSelect } from '@/components/Form/RadioSelect';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { InstanceProps } from '@/components/Modal';
import { Stepper } from '@/components/Stepper';
import { SubmitButton } from '@/components/SubmitButton';
import { useRailcards } from '@/hooks/useDiscounts';

enum Discounts {
  TWO_TOGETHER = 'Two Together Railcard',
  GROUP_SAVE = 'Group Save Discount'
}

export interface Props
  extends InstanceProps<
    [
      {
        ticketType: TicketType;
        ticketCount: number;
        groupSave: boolean;
        twoTogether: boolean;
        railcardId?: number;
      }
    ],
    { ticketType: TicketType }
  > {}

const getRailcardId = (railcardName: Discounts, railcards: Railcard[]) =>
  railcards.find((r) => r.name === railcardName)?.id;

const schema = yup
  .object({
    ticketType: yup.mixed<TicketType>().oneOf(Object.values(TicketType)).required().label('ticket type'),
    maxTicketCount: yup.number().optional().label('carnet type'),
    ticketCount: yup.number().max(yup.ref('maxTicketCount')).required().label('ticket count'),
    groupSave: yup.boolean().optional().default(false).label('group save'),
    twoTogether: yup.boolean().optional().default(false).label('two together')
  })
  .required();

export const TicketCountModal: React.FC<Props> = ({ data, onAction, onClose }) => {
  const { data: railcards = [] } = useRailcards({ ticketType: data.ticketType, all: false });

  const initialValues: yup.InferType<typeof schema> = {
    ticketType: data.ticketType,
    maxTicketCount: data.ticketType === TicketType.CARNET ? undefined : data.ticketType === TicketType.FLEXI ? 8 : 10,
    ticketCount: data.ticketType === TicketType.FLEXI ? 0 : 1,
    groupSave: false,
    twoTogether: false
  };

  const onSubmit = (values: yup.InferType<typeof schema>) => {
    const railcardId = values.groupSave
      ? getRailcardId(Discounts.GROUP_SAVE, railcards)
      : values.twoTogether
      ? getRailcardId(Discounts.TWO_TOGETHER, railcards)
      : undefined;

    onAction({ ...values, railcardId });
  };

  return (
    <Form {...{ schema, initialValues, onSubmit }} className="text-[#003F2E] sm:min-w-[360px]">
      {({ values, submitting }) => (
        <div className="space-y-4">
          <header className="flex justify-between space-x-8">
            <h3 className="text-lg font-bold">
              <FormattedMessage id={translations.pages.progress.ticketCount.title} />
            </h3>

            <CloseButton onClick={onClose} />
          </header>

          {data.ticketType === TicketType.CARNET && (
            <div className="space-y-1">
              <label className="text-sm text-[#003F2EB2]">
                <FormattedMessage id={translations.pages.progress.ticketCount.carnetType} />
              </label>

              <div className="flex items-center">
                <Field id={`carnet-type-${5}`} name="maxTicketCount" type="radio" value={5} parse={(v) => +v}>
                  {({ input, meta, ...props }) => (
                    <RadioSelect {...props} {...input} className="rounded-r-none border-r-0" contentClassName="text-sm">
                      5 ticket carnet
                    </RadioSelect>
                  )}
                </Field>

                <Field id={`carnet-type-${10}`} name="maxTicketCount" type="radio" value={10} parse={(v) => +v}>
                  {({ input, meta, ...props }) => (
                    <RadioSelect {...props} {...input} className="rounded-l-none" contentClassName="text-sm">
                      10 ticket carnet
                    </RadioSelect>
                  )}
                </Field>
              </div>
            </div>
          )}

          {!!values.maxTicketCount && (
            <div className="space-y-1">
              <label className="text-sm text-[#003F2EB2]">
                <FormattedMessage
                  id={
                    data.ticketType === TicketType.CARNET
                      ? translations.pages.progress.ticketCount.carnetCount
                      : data.ticketType === TicketType.FLEXI
                      ? translations.pages.progress.ticketCount.flexiUsed
                      : translations.pages.progress.ticketCount.count
                  }
                />
              </label>

              <ValidatedField
                field={Stepper}
                id="ticket-count"
                name="ticketCount"
                type="text"
                readOnly={submitting}
                parse={(value) => +value}
                min={values.ticketType === TicketType.FLEXI ? 0 : 1}
                max={values.maxTicketCount}
                fieldClassName="h-12"
              />
            </div>
          )}

          {data.ticketType !== TicketType.CARNET &&
            data.ticketType !== TicketType.FLEXI &&
            values.ticketCount > 1 &&
            values.ticketCount <= 10 && (
              <div className="space-y-1">
                <label className="flex cursor-pointer select-none text-sm text-[#003F2EB2]">
                  <Field
                    id={values.ticketCount === 2 ? 'two-together' : 'group-save'}
                    name={values.ticketCount === 2 ? 'twoTogether' : 'groupSave'}
                    type="checkbox"
                    disabled={submitting}
                  >
                    {({ input, meta, ...props }) => (
                      <input
                        {...props}
                        {...input}
                        className="form-checkbox mr-2 h-5 w-5 cursor-pointer rounded border-[#003F2E4D] text-[#003F2E]"
                      />
                    )}
                  </Field>

                  <FormattedMessage
                    id={values.ticketCount === 2 ? translations.misc.twoTogether : translations.misc.groupSave}
                  />
                </label>
              </div>
            )}

          <div className="flex justify-end space-x-4">
            <Button appearance="secondary" type="button" onClick={onClose}>
              <FormattedMessage id={translations.buttons.close} />
            </Button>

            <SubmitButton disabled={!values.maxTicketCount}>
              <FormattedMessage id={translations.pages.progress.ticketCount.next} />
            </SubmitButton>
          </div>
        </div>
      )}
    </Form>
  );
};
