/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Booking } from '../models/Booking';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BookingService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Returns a booking.
   * @returns Booking Booking found
   * @throws ApiError
   */
  public getBooking({
    reference,
    email,
  }: {
    /**
     * Booking reference
     */
    reference: string,
    /**
     * Email address of the customer
     */
    email: string,
  }): CancelablePromise<Booking> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/bookings/{reference}',
      path: {
        'reference': reference,
      },
      query: {
        'email': email,
      },
    });
  }

}
