import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { Step, StepHeader } from '@/components/Step';

import { OtpForm } from './OtpForm';

interface Props {
  onBack(): void;
  onSubmit(code: string): Promise<void>;
}

export const OtpStep: React.FC<Props> = ({ onBack, onSubmit }) => {
  return (
    <Step>
      <StepHeader title={<FormattedMessage id={translations.pages.progress.customerDetails.otp.title} />} />

      <OtpForm {...{ onBack, onSubmit }} />
    </Step>
  );
};
