/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GlobalPaymentsInitializationResponse } from '../models/GlobalPaymentsInitializationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CardStorageService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Returns the data needed for the hpp form initialization.
   * @returns GlobalPaymentsInitializationResponse The hpp request data needed for the form initialization.
   * @throws ApiError
   */
  public initiateCardStorage(): CancelablePromise<GlobalPaymentsInitializationResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/payment-methods/global-payments/initiate',
    });
  }

}
