import React from 'react';
import { FormattedMessage } from 'react-intl';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { translations } from '@/locales';

interface Props {
  className?: string;
  onClick(): void;
}

export const CloseButton: React.FC<React.PropsWithChildren<Props>> = (props) => (
  <button type="button" {...props}>
    <span className="sr-only">
      <FormattedMessage id={translations.buttons.close} />
    </span>
    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
  </button>
);
