import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import classnames from 'classnames';
import * as React from 'react';

import { getInputInvalidClassNames, getInputReadOnlyClassNames } from './Form/Input';

interface Props {
  value: number;
  step?: number;
  min?: number;
  max?: number;
  className?: string;
  readOnly?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  onChange(value: number): void;
}

export const Stepper: React.FC<Props> = ({
  value,
  step = 1,
  min,
  max,
  invalid,
  readOnly,
  disabled,
  className,
  onChange,
  ...rest
}) => {
  const decrement = React.useCallback(
    () =>
      !readOnly &&
      !disabled &&
      // When min = 0, min || -Infinity returns -Infinity and the stepper would go below 0
      onChange(Math.max(Math.min(value - step, max || Infinity), min ?? -Infinity)),
    [readOnly, disabled, value, step, min, max, onChange]
  );

  const increment = React.useCallback(
    () => !readOnly && !disabled && onChange(Math.max(Math.min(value + step, max || Infinity), min ?? -Infinity)),
    [readOnly, disabled, value, step, min, max, onChange]
  );

  return (
    <div className={classnames('inline-flex', className)}>
      <button
        type="button"
        className="relative z-0 flex w-10 flex-shrink-0 items-center justify-center"
        onClick={decrement}
        onMouseDown={(e) => e.preventDefault()}
      >
        <MinusIcon className="h-5 w-5 stroke-2" />
      </button>

      <div className="flex flex-1 items-center justify-center">
        <input
          {...{ value, readOnly, disabled }}
          {...rest}
          className={classnames(
            'form-input -mx-10 h-full w-32 rounded text-center text-sm',
            getInputInvalidClassNames(invalid),
            getInputReadOnlyClassNames(readOnly)
          )}
          readOnly
        />
      </div>

      <button
        type="button"
        className="relative z-0 flex w-10 flex-shrink-0 items-center justify-center"
        onClick={increment}
        onMouseDown={(e) => e.preventDefault()}
      >
        <PlusIcon className="h-5 w-5" />
      </button>
    </div>
  );
};
