import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { FaceFrownIcon, FaceSmileIcon } from '@heroicons/react/24/outline';

import { translations } from '@/locales';

import { Form } from '@/components/Form';
import { SubmitAutomaticallyWhenValid } from '@/components/Form/SubmitAutomaticallyWhenValid';
import { RadioSelect } from '@/components/Form/RadioSelect';
import { Step, StepFooter, StepHeader } from '@/components/Step';
import { BackButton } from '@/components/BackButton';

const schema = yup.object({ login: yup.number().required() }).required();

const items = [
  { icon: FaceSmileIcon, value: 1, name: translations.misc.yes },
  { icon: FaceFrownIcon, value: 0, name: translations.misc.no }
];

interface Props {
  onBack(): void;
  onSubmit(login: boolean): void;
}

export const CheckUserStep: React.FC<Props> = ({ onBack, ...props }) => {
  const onSubmit = React.useCallback(({ login }: yup.InferType<typeof schema>) => props.onSubmit(login === 1), [props]);

  return (
    <Step>
      <StepHeader title={<FormattedMessage id={translations.pages.progress.customerDetails.checkUser.title} />} />

      <Form {...{ schema, onSubmit }}>
        {() => (
          <React.Fragment>
            <SubmitAutomaticallyWhenValid />

            <div className="space-y-4 max-w-[320px]">
              {items.map(({ icon: Icon, value, name }) => (
                <Field key={name} id={`login-${value}`} name="login" type="radio" {...{ value }}>
                  {({ input, meta, ...props }) => (
                    <RadioSelect {...props} {...input} contentClassName="flex items-center">
                      <Icon className="w-5 h-5 mr-4" />
                      <FormattedMessage id={name} />
                    </RadioSelect>
                  )}
                </Field>
              ))}
            </div>

            <StepFooter>
              <BackButton onClick={onBack} />
            </StepFooter>
          </React.Fragment>
        )}
      </Form>
    </Step>
  );
};
