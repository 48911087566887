import { BankDetails, CardDetails } from '@repay/api-sdk';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import useSWR from 'swr';

import { apiClient } from '@/services/api-client';

import { translations } from '@/locales';

import { useAuth } from '@/hooks/useAuth';

import { LoginForm } from '../ProgressPage/steps/ContactDetailsStep/LoginForm';
import { OtpForm } from '../ProgressPage/steps/ContactDetailsStep/OtpForm';
import { BankDetailsForm } from '../ProgressPage/steps/PaymentDetails/BankDetailsForm';
import { CardDetailsForm } from '../ProgressPage/steps/PaymentDetails/CardDetailsForm';
import { TicketImagesForm } from '../ProgressPage/steps/TicketDetails/TicketImages/TicketImagesForm';
import { RefundLayout } from '../RefundStatusPage';

enum RefundUpdateState {
  LOGIN = 'login',
  OTP = 'otp',
  CARD = 'cardDetails',
  BANK = 'bankDetails',
  IMAGES = 'ticketImages',
  SUCCESS = 'success'
}

const tokenExpiresIn = '30m';

export const RefundUpdatePage: React.FC = () => {
  const params = useParams();

  const { type, claimRef, hash: updateHash } = params;

  const { verifyOtp, sendOtp, authenticated } = useAuth();

  const [step, setStep] = React.useState<RefundUpdateState>(
    authenticated ? (type as RefundUpdateState) : RefundUpdateState.LOGIN
  );

  const {
    data: refund,
    isLoading: loading,
    error
  } = useSWR(`getRefund`, () => apiClient.refund.getRefund({ claimRef: claimRef!, updateHash }), {
    revalidateOnMount: true,
    revalidateIfStale: true,
    revalidateOnFocus: false
  });

  React.useEffect(() => {
    if (!authenticated) {
      setStep(RefundUpdateState.LOGIN);
    }
  }, [authenticated]);

  const onLoginSubmit = React.useCallback(
    async (userIdentifier: { email: string }) => {
      await sendOtp({ ...userIdentifier });

      setStep(RefundUpdateState.OTP);
    },
    [sendOtp]
  );

  const onOtpSubmit = React.useCallback(
    async (code: string) => {
      await verifyOtp({
        code,
        options: { tokenExpiresIn }
      });

      setStep(type as RefundUpdateState);
    },
    [type, verifyOtp]
  );

  const onBankDetailsSubmit = React.useCallback(
    async (values: BankDetails) => {
      await apiClient.refund.updateRefundBankDetails({ id: refund!.id, requestBody: values });

      setStep(RefundUpdateState.SUCCESS);
    },
    [refund]
  );

  const onCardDetailsSubmit = React.useCallback(
    async (paymentDetails: CardDetails) => {
      await apiClient.refund.updateRefundCardDetails({ id: refund!.id, requestBody: paymentDetails });

      setStep(RefundUpdateState.SUCCESS);
    },
    [refund]
  );

  return (
    <RefundLayout {...{ claimRef, loading, error }} lastUpdated={refund?.lastUpdated} created={refund?.created}>
      <div className="flex flex-1 flex-col items-center justify-center px-10 md:p-12">
        <h1 className="mb-12 text-center text-lg font-bold md:text-4xl">
          <FormattedMessage id={translations.enum.refundUpdateSteps[step]} values={{ type }} />
        </h1>

        {step === RefundUpdateState.LOGIN && <LoginForm className="w-full md:max-w-[30%]" onSubmit={onLoginSubmit} />}

        {step === RefundUpdateState.OTP && <OtpForm onSubmit={onOtpSubmit} />}

        {step === RefundUpdateState.CARD && (
          <div className="w-full max-w-3xl">
            <CardDetailsForm onSubmit={onCardDetailsSubmit} />
          </div>
        )}

        {step === RefundUpdateState.BANK && (
          <BankDetailsForm
            className="w-full max-w-3xl"
            onSubmit={onBankDetailsSubmit}
            submitButtonMessage={<FormattedMessage id={translations.buttons.updateDetails} />}
          />
        )}

        {step === RefundUpdateState.IMAGES && (
          <TicketImagesForm
            ticket={{ ...refund!, reason: { reason: refund!.reason } }}
            onSubmit={() => setStep(RefundUpdateState.SUCCESS)}
            className="w-full max-w-4xl"
            submitButtonMessage={<FormattedMessage id={translations.buttons.updateDetails} />}
          />
        )}
      </div>
    </RefundLayout>
  );
};
