import { CheckCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { InstanceProps } from '@/components/Notification';

export interface Props extends InstanceProps<[]> {
  index: number;
}

export const TickedAddedNotification = React.forwardRef<HTMLDivElement, Props>(({ index, onClose }, ref) => {
  React.useEffect(() => {
    const timeout = setTimeout(onClose, 4000);
    return () => clearTimeout(timeout);
  }, [onClose]);

  return (
    <div
      {...{ ref }}
      className="pointer-events-auto w-full max-w-sm overflow-hidden rounded bg-[#F3F0E8] text-sm shadow-lg ring-1 ring-black ring-opacity-5 md:rounded-lg"
    >
      <div className="p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            <CheckCircleIcon className="h-6 w-6 text-[#003F2E]" aria-hidden="true" />
          </div>

          <div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="font-medium text-[#003F2E]">Making progress</p>

            <p className="mt-1 text-gray-500">
              <FormattedMessage id={translations.pages.progress.ticketDetails.ticketAdded} values={{ index }} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});
