import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { InstanceProps, ModalHeader, ModalLayout } from '@/components/Modal';

import smartcard from '@/assets/cards/ticket-smartcard.svg';

export interface Props extends InstanceProps<[], any> {}

export const TicketSmartcardHelpModal: React.FC<Props> = ({ onClose }) => (
  <ModalLayout>
    <ModalHeader centered {...{ onClose }}>
      <FormattedMessage id={translations.fields.scn} />
    </ModalHeader>

    <div className="grid grid-cols-1 gap-4 pt-4">
      <img src={smartcard} alt="" className="md:w-96" />
    </div>
  </ModalLayout>
);
