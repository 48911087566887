/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Customer } from '../models/Customer';
import type { OtpSendPayload } from '../models/OtpSendPayload';
import type { OtpVerificationPayload } from '../models/OtpVerificationPayload';
import type { OtpVerificationResponse } from '../models/OtpVerificationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthenticationService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Sends a confirmation code.
   * @returns any If the credentials matches our records, a message will be delivered.
   * @throws ApiError
   */
  public sendOtp({
    requestBody,
  }: {
    requestBody: OtpSendPayload,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/otp/send',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Verifies the confirmation code.
   * @returns OtpVerificationResponse The customer the code belongs to.
   * @throws ApiError
   */
  public verifyOtp({
    requestBody,
  }: {
    requestBody: OtpVerificationPayload,
  }): CancelablePromise<OtpVerificationResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/otp/verify',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Registers a customer account.
   * @returns any Customer created.
   * @throws ApiError
   */
  public register({
    requestBody,
  }: {
    requestBody: Customer,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/register',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
