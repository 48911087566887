import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { InstanceProps, ModalHeader, ModalLayout } from '@/components/Modal';

import blue from '@/assets/cards/ticket-number-blue.svg';
import red from '@/assets/cards/ticket-number-red.svg';
import yellow from '@/assets/cards/ticket-number-yellow.svg';

export interface Props extends InstanceProps<[], any> {}

export const TicketNumberHelpModal: React.FC<Props> = ({ onClose }) => (
  <ModalLayout>
    <ModalHeader centered {...{ onClose }}>
      <FormattedMessage id={translations.fields.ticketNumber} />
    </ModalHeader>

    <div className="grid grid-cols-2 gap-4">
      <img src={red} alt="" />
      <img src={yellow} alt="" />
      <img src={red} alt="" />
      <img src={blue} alt="" />
    </div>
  </ModalLayout>
);
