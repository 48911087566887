import { CardDetails, PurchaseMethod } from '@repay/api-sdk';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { ProgressManager } from '@/hooks/useProgressManager';

import { BackButton } from '@/components/BackButton';
import { Step, StepFooter, StepHeader } from '@/components/Step';

import { CardDetailsForm } from './CardDetailsForm';

export const CardDetailsStep: React.FC = () => {
  const { back, updatePaymentDetails } = ProgressManager.useContainer();

  const onSubmit = React.useCallback(
    async (paymentDetails: CardDetails) => await updatePaymentDetails(PurchaseMethod.PLASTIC, paymentDetails),
    [updatePaymentDetails]
  );

  return (
    <React.Fragment>
      <Step>
        <StepHeader title={<FormattedMessage id={translations.pages.progress.purchaseMethod.card.title} />} />

        <CardDetailsForm {...{ onSubmit }} />

        <StepFooter>
          <BackButton onClick={() => back()} />
        </StepFooter>
      </Step>
    </React.Fragment>
  );
};
