import React from 'react';
import { Field } from 'react-final-form';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import * as yup from 'yup';

import { ProgressManager } from '@/hooks/useProgressManager';
import { useStations } from '@/hooks/useStations';
import { translations } from '@/locales';

import { BackButton } from '@/components/BackButton';
import { Form } from '@/components/Form';
import { LoadingIndicator, LoadingMessage } from '@/components/Loading';
import { Step, StepFooter, StepHeader } from '@/components/Step';
import { SubmitButton } from '@/components/SubmitButton';

const schema = yup.object({ tickets: yup.array().of(yup.number().required()).required() }).required();

export const TicketSelectionStep: React.FC = () => {
  const { state, back, selectTickets } = ProgressManager.useContainer();

  const stationIds = React.useMemo(
    () => Array.from(new Set(state.selectableTickets.map((t) => [t.originStationId, t.destinationStationId]).flat())),
    [state.selectableTickets]
  );

  const { data: stations = [], isValidating: loading } = useStations(stationIds);

  const onSubmit = React.useCallback(
    ({ tickets: selection }: yup.InferType<typeof schema>) => {
      return selectTickets(
        selection
          .slice()
          .sort((a, b) => a - b)
          .map((selectedIndex) => state.selectableTickets[selectedIndex])
      );
    },
    [state.selectableTickets, selectTickets]
  );

  return (
    <Step>
      <StepHeader title={<FormattedMessage id={translations.pages.progress.ticketSelection.title} />} />

      <Form
        className="w-fit"
        initialValues={{
          tickets: state.tickets?.map((t) => state.selectableTickets?.indexOf(t) ?? -1).filter((i) => i >= 0) ?? []
        }}
        {...{ schema, onSubmit }}
      >
        {({ values, submitting, form }) => (
          <React.Fragment>
            <div className="-m-2 overflow-x-auto">
              <div className="inline-block min-w-full align-middle">
                <div className="overflow-hidden p-2">
                  <table className="min-w-full divide-y divide-[#F3F0E8]">
                    <thead className="text-left text-sm font-semibold text-[#003F2E]">
                      <tr>
                        <th scope="col" className="w-8 py-4 pr-3 align-bottom" />
                        <th scope="col" className="py-4 px-3 align-bottom">
                          <FormattedMessage id={translations.fields.ticketType} />
                        </th>
                        <th scope="col" className="py-4 px-3 align-bottom">
                          <FormattedMessage id={translations.fields.ticketFormat} />
                        </th>
                        <th scope="col" className="py-4 px-3 align-bottom">
                          <FormattedMessage id={translations.fields.origin} />
                        </th>
                        <th scope="col" className="py-4 px-3 align-bottom">
                          <FormattedMessage id={translations.fields.destination} />
                        </th>
                        <th scope="col" className="py-4 px-3 align-bottom">
                          <FormattedMessage id={translations.fields.validFrom} />
                        </th>
                        <th scope="col" className="py-4 px-3 align-bottom">
                          <FormattedMessage id={translations.fields.validTo} />
                        </th>
                        <th scope="col" className="py-4 pl-3 align-bottom">
                          <FormattedMessage id={translations.fields.price} />
                        </th>
                      </tr>
                    </thead>

                    <tbody className="divide-y divide-[#F3F0E8] text-sm">
                      {loading ? (
                        <tr>
                          <td colSpan={8} className="py-4 text-sm">
                            <LoadingMessage>
                              <LoadingIndicator className="h-5 w-5" />
                              <FormattedMessage tagName="div" id={translations.misc.loading} />
                            </LoadingMessage>
                          </td>
                        </tr>
                      ) : (
                        state.selectableTickets.map((ticket, index) => (
                          <tr
                            key={index}
                            className="cursor-pointer select-none hover:bg-gray-50"
                            onClick={() => {
                              const tickets = form.getFieldState('tickets')?.value ?? [];

                              const checked = tickets.includes(index);

                              form.change(
                                'tickets',
                                checked ? tickets.filter((item) => item !== index) : [...tickets, index]
                              );
                            }}
                          >
                            <td className="whitespace-nowrap py-4 pr-3">
                              <Field name="tickets" type="checkbox" value={index}>
                                {({ input, meta, ...props }) => (
                                  <label className="-m-2 cursor-pointer p-2">
                                    <input
                                      {...props}
                                      {...input}
                                      className="form-checkbox h-5 w-5 cursor-pointer rounded border-[#003F2E] text-[#003F2E] focus:ring-[#003F2E]"
                                    />
                                  </label>
                                )}
                              </Field>
                            </td>
                            <td className="whitespace-nowrap py-4 px-3">
                              <FormattedMessage id={translations.enum.ticketType[ticket.type]} />
                            </td>
                            <td className="whitespace-nowrap py-4 px-3">
                              <FormattedMessage id={translations.enum.ticketFormat[ticket.format]} />
                            </td>
                            <td className="whitespace-nowrap py-4 px-3">
                              {stations.find((item) => item.id === ticket.originStationId)?.name}
                            </td>
                            <td className="whitespace-nowrap py-4 px-3">
                              {stations.find((item) => item.id === ticket.destinationStationId)?.name}
                            </td>
                            <td className="whitespace-nowrap py-4 px-3">
                              <FormattedDate
                                value={ticket.validFrom}
                                year="numeric"
                                month="short"
                                day="2-digit"
                                timeZone="UTC"
                              />
                            </td>
                            <td className="whitespace-nowrap py-4 px-3">
                              <FormattedDate
                                value={ticket.validTo}
                                year="numeric"
                                month="short"
                                day="2-digit"
                                timeZone="UTC"
                              />
                            </td>
                            <td className="whitespace-nowrap py-4 pl-3">
                              <FormattedNumber
                                value={ticket.value / 100}
                                style="currency"
                                currency="GBP"
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                              />
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <StepFooter>
              <SubmitButton loading={submitting} disabled={!values.tickets.length}>
                <FormattedMessage id={translations.buttons.continue} />
              </SubmitButton>

              <BackButton onClick={() => back()} />
            </StepFooter>
          </React.Fragment>
        )}
      </Form>
    </Step>
  );
};
