/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum FulfillmentMethod {
  SMARTCARD = 'smartcard',
  COLLECTION = 'collection',
  BARCODE = 'barcode',
  POSTED = 'posted',
}
