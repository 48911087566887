import { PurchaseMethod, Ticket, TicketClass, TicketFormat, TicketType } from '@repay/api-sdk';
import dayjs from 'dayjs';
import { FormApi } from 'final-form';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { translations } from '@/locales';

import { areSameDates, isDateAfterMaxMonths, isDateBefore } from '@/utils/dateFunctions';

import { useRailcards } from '@/hooks/useDiscounts';
import { useModal } from '@/hooks/useModal';
import { ProgressManager, RefundTicket } from '@/hooks/useProgressManager';
import { useSellingStations, useStations } from '@/hooks/useStations';
import { apiClient } from '@/services/api-client';

import { Alert } from '@/components/Alert';
import { BackButton } from '@/components/BackButton';
import { Button } from '@/components/Button';
import { Form } from '@/components/Form';
import { CurrencyInput } from '@/components/Form/CurrencyInput';
import { DateInput, DATE_FORMAT } from '@/components/Form/DateInput';
import { Input } from '@/components/Form/Input';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { MaskedInput } from '@/components/Form/MaskedInput';
import { Select } from '@/components/Form/Select';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { HelpButton } from '@/components/HelpButton';
import { Modal } from '@/components/Modal';
import { Step, StepFooter, StepFooterButtons, StepHeader } from '@/components/Step';
import { SubmitButton } from '@/components/SubmitButton';

import { TicketBarcodeHelpModal } from '../modals/TicketBarcodeHelpModal';
import { TicketNumberHelpModal } from '../modals/TicketNumberHelpModal';
import { TicketReferenceHelpModal } from '../modals/TicketReferenceHelpModal';

enum InputMethod {
  Search,
  Manual,
  Found,
  NotFound
}

const ticketNumbers: Record<TicketType, [string, string]> = {
  [TicketType.SINGLE]: ['77641', '46634046'],
  [TicketType.RETURN]: ['24397', '14975177'],
  [TicketType.RETURN_PARTIAL]: ['???', '???'],
  [TicketType.DAY_RETURN_PARTIAL]: ['???', '???'],
  [TicketType.DAY_RETURN]: ['???', '???'],
  [TicketType.DAY_TRAVELCARD]: ['51772', '47137138'],
  [TicketType.ADVANCE]: ['???', '???'],
  [TicketType.CARNET]: ['15072', '71156086'],
  [TicketType.WEEKLY]: ['03567', '46925312'],
  [TicketType.MONTHLY]: ['25236', '45055463'],
  [TicketType.MONTHLY_PLUS]: ['34185', '43585486'],
  [TicketType.FLEXI]: ['31035', '45215347'],
  [TicketType.RANGER]: ['???', '???'],
  [TicketType.ROVER]: ['???', '???'],
  [TicketType.ANNUAL]: ['31035', '44825329']
};

export const convertTicket = <T extends Partial<RefundTicket>>(ticket: T): T => ({
  ...ticket,
  dateOfTravel:
    ticket.dateOfTravel &&
    (dayjs(ticket.dateOfTravel, DATE_FORMAT).isValid()
      ? ticket.dateOfTravel
      : dayjs(ticket.dateOfTravel).format(DATE_FORMAT)),
  validFrom:
    ticket.validFrom &&
    (dayjs(ticket.validFrom, DATE_FORMAT).isValid() ? ticket.validFrom : dayjs(ticket.validFrom).format(DATE_FORMAT)),
  validTo:
    ticket.validTo &&
    (dayjs(ticket.validTo, DATE_FORMAT).isValid() ? ticket.validTo : dayjs(ticket.validTo).format(DATE_FORMAT)),
  value: ticket.value && ticket.value / 100,
  inputMethod: ticket.value ? InputMethod.Found : InputMethod.Search
});

export const TicketDetailsStep: React.FC = () => {
  const intl = useIntl();

  const { state, back, ticketIndex, setTicketIndex, updateTicketByIndex } = ProgressManager.useContainer();

  const schema = React.useMemo(
    () =>
      yup
        .object({
          utn: yup
            .string()
            .matches(/[a-zA-Z0-9]{11}/, translations.customValidation.ticketUTN)
            .label(translations.fields.utn)
            .when('format', { is: TicketFormat.BARCODE, then: (s) => s.required() }),
          number: yup
            .string()
            .matches(/\d{5}/, translations.customValidation.ticketNumber)
            .test('usedNumber', translations.customValidation.usedTicketNumber, (value) => {
              const usedNumber = state.tickets.some(
                (ticket, index) => ticket?.number === value && index !== ticketIndex
              );
              return !usedNumber;
            })
            .label(translations.fields.ticketNumber)
            .when('format', { is: TicketFormat.PAPER, then: (s) => s.required() }),
          reference: yup
            .string()
            .matches(/\d{4}-?\d{4}/, translations.customValidation.ticketReference)
            .test('usedReference', translations.customValidation.usedTicketReference, (value) => {
              const usedReference = state.tickets.some(
                (ticket, index) => ticket?.reference === value && index !== ticketIndex
              );
              return !usedReference;
            })
            .test(
              'isBookingReference',
              intl.formatMessage({ id: translations.customValidation.isBookingReference }, { reference: '7871' }),
              (value) => {
                if (!value) return true;

                const bookingReferenceEnding = '7871';

                return !value.endsWith(bookingReferenceEnding);
              }
            )
            .label(translations.fields.ticketReference)
            .when('format', { is: TicketFormat.PAPER, then: (s) => s.required() }),
          inputMethod: yup.mixed<InputMethod>().required(),
          type: yup
            .mixed<TicketType>()
            .oneOf(Object.values(TicketType))
            .label(translations.fields.ticketType)
            .when('inputMethod', { is: InputMethod.Search, otherwise: (s) => s.required() }),
          purchaseStationId: yup
            .string()
            .label(translations.fields.purchaseStation)
            .when('inputMethod', { is: InputMethod.Search, otherwise: (s) => s.required() }),
          purchaseMethod: yup
            .string()
            .label(translations.fields.purchaseMethod)
            .when('inputMethod', { is: InputMethod.Search, otherwise: (s) => s.required() })
            .test({
              name: 'samePurchaseMethodForAllTickets',
              test: function (purchaseMethod) {
                if (!purchaseMethod || !state.payment?.method || ticketIndex === 0) return true;

                return purchaseMethod === state.payment.method;
              },
              message: translations.customValidation.samePurchaseMethodForAllTickets
            }),
          originStationId: yup
            .string()
            .label(translations.fields.origin)
            .when('inputMethod', { is: InputMethod.Search, otherwise: (s) => s.required() }),
          destinationStationId: yup
            .string()
            .label(translations.fields.destination)
            .when('inputMethod', { is: InputMethod.Search, otherwise: (s) => s.required() }),
          validFrom: yup
            .string()
            .date()
            .label(translations.fields.validFrom)
            .when('inputMethod', { is: InputMethod.Search, otherwise: (s) => s.required() }),
          dateOfTravel: yup
            .string()
            .date()
            .label(translations.fields.dateOfTravel)
            .when('inputMethod', { is: InputMethod.Search, otherwise: (s) => s.required() })
            .test({
              name: 'inValidityRange',
              test: function (dateOfTravel) {
                if (!dateOfTravel) return true;

                const validFrom = this.parent.validFrom;
                const validTo = this.parent.validTo;

                return isDateBefore(dateOfTravel, validTo) && isDateAfterMaxMonths(dateOfTravel, validFrom, 0);
              },
              message: translations.customValidation.inRange,
              params: {
                from: yup.ref('validFrom'),
                to: yup.ref('validTo')
              }
            }),
          validTo: yup
            .string()
            .date()
            .label(translations.fields.validTo)
            .when('inputMethod', { is: InputMethod.Search, otherwise: (s) => s.required() })
            .test({
              name: 'validToDateConsistency',
              exclusive: false,
              test: function (validTo) {
                if (!validTo || this.parent.inputMethod === InputMethod.Search) return true;

                const validFrom = this.parent.validFrom;

                const isDayReturnTicket =
                  state.tickets[ticketIndex].type === TicketType.DAY_RETURN ||
                  state.tickets[ticketIndex].type === TicketType.DAY_RETURN_PARTIAL;

                if (isDayReturnTicket) return areSameDates(validFrom, validTo);

                const isReturnTicket =
                  state.tickets[ticketIndex].type === TicketType.RETURN ||
                  state.tickets[ticketIndex].type === TicketType.RETURN_PARTIAL;

                if (isReturnTicket) return isDateAfterMaxMonths(validTo, validFrom);

                return isDateBefore(validFrom, validTo) || areSameDates(validFrom, validTo);
              },
              message: function (params) {
                return params.isDayReturnTicket
                  ? translations.customValidation.sameDate
                  : params.isReturnTicket
                  ? translations.customValidation.withinAMonth
                  : translations.customValidation.afterDate;
              },
              params: {
                date: yup.ref('validFrom'),
                isDayReturnTicket:
                  state.tickets[ticketIndex].type === TicketType.DAY_RETURN ||
                  state.tickets[ticketIndex].type === TicketType.DAY_RETURN_PARTIAL,
                isReturnTicket:
                  state.tickets[ticketIndex].type === TicketType.RETURN ||
                  state.tickets[ticketIndex].type === TicketType.RETURN_PARTIAL
              }
            }),
          value: yup
            .number()
            .min(0)
            .max(1000)
            .label(translations.fields.value)
            .when('inputMethod', { is: InputMethod.Search, otherwise: (s) => s.required() }),
          class: yup
            .mixed<TicketClass>()
            .oneOf(Object.values(TicketClass))
            .label(translations.fields.ticketClass)
            .when('inputMethod', { is: InputMethod.Search, otherwise: (s) => s.required() }),
          format: yup.mixed<TicketFormat>().oneOf(Object.values(TicketFormat)),
          railcardId: yup.mixed<number>().optional()
        })
        .required(),
    [ticketIndex, intl, state.payment?.method, state.tickets]
  );

  const count = state.tickets.length;
  const ticket = React.useMemo(() => state.tickets[ticketIndex], [state, ticketIndex]);

  const { data: stations = [] } = useStations();
  const { data: sellingStations = [] } = useSellingStations();
  const { data: railcards = [], mutate: reloadRailcards } = useRailcards({ ticketType: state.ticketType! });

  React.useEffect(() => {
    reloadRailcards();
  }, []);

  const ticketNumberModal = useModal(TicketNumberHelpModal);
  const ticketReferenceModal = useModal(TicketReferenceHelpModal);
  const ticketBarcodeModal = useModal(TicketBarcodeHelpModal);

  const initialValues = React.useMemo(
    () => ({
      ...convertTicket(ticket),
      type: ticket.type ?? state.ticketType,
      inputMethod: state.ticketFormat === TicketFormat.SMARTCARD ? InputMethod.Manual : InputMethod.Search
    }),
    [state, ticket]
  );

  const onBack = React.useCallback(
    ({ inputMethod }: yup.InferType<typeof schema>, form: FormApi<yup.InferType<typeof schema>>) => {
      if (inputMethod !== InputMethod.Search && ticket.format !== TicketFormat.SMARTCARD)
        return form.change('inputMethod', InputMethod.Search);

      if (ticketIndex > 0) setTicketIndex((index) => index - 1);

      return back();
    },
    [ticket.format, ticketIndex, setTicketIndex, back]
  );

  const onSubmit = React.useCallback(
    async ({ inputMethod, ...value }: yup.InferType<typeof schema>, form: FormApi) => {
      if (inputMethod !== InputMethod.Search) {
        updateTicketByIndex(value as unknown as Ticket);

        form.batch(() => {
          form.reset();
          form.change('inputMethod', InputMethod.Search);
        });

        return;
      }

      const promise =
        ticket.format === TicketFormat.BARCODE
          ? apiClient.ticket.getTicketByUtn({ utn: value.utn! })
          : apiClient.ticket.getTicketByNumber({
              number: value.number!,
              reference: value.reference!,
              type: value.type!
            });

      const data = await promise
        .then(convertTicket)
        .then((value) => ({ ...value, inputMethod: InputMethod.Found }))
        .catch(() => ({ ...value, inputMethod: InputMethod.NotFound }));

      form.batch(() => {
        form.reset();
        form.initialize(data);
      });
    },
    [ticket, updateTicketByIndex]
  );

  return (
    <Step className="relative max-w-4xl text-[#003F2E]">
      <StepHeader
        title={
          <FormattedMessage
            id={translations.pages.progress.ticketDetails.title}
            values={{ count, index: ticketIndex + 1 }}
          />
        }
      />

      <Form {...{ initialValues, schema, onSubmit }}>
        {({ values, submitting, form, initialValues }) => (
          <React.Fragment>
            <div className="space-y-8">
              <Alert type="info" title="Test instructions:">
                <div>
                  {ticket.format === TicketFormat.PAPER && (
                    <div>
                      <div>
                        Ticket number: <span className="font-medium">{ticketNumbers[ticket.type][0]}</span>
                      </div>
                      <div>
                        Ticket reference: <span className="font-medium">{ticketNumbers[ticket.type][1]}</span>
                      </div>
                    </div>
                  )}
                  {ticket.format === TicketFormat.BARCODE && (
                    <div>
                      Unique ticket number: <span className="font-medium">TPCKXQ3HYRM</span>
                    </div>
                  )}
                </div>
              </Alert>

              {values.inputMethod === InputMethod.NotFound && (
                <Alert type="error" title="We didn't find your ticket">
                  <div>Please fill in the details manually.</div>
                </Alert>
              )}

              <div className="grid gap-4 md:grid-cols-2">
                {ticket.format === TicketFormat.BARCODE ? (
                  <div className="relative">
                    <ValidatedField
                      field={InputWithLabel}
                      as={MaskedInput}
                      mask={Array.from({ length: 11 }).map(() => /[a-zA-Z0-9]/)}
                      id="utn"
                      name="utn"
                      type="text"
                      label={<FormattedMessage id={translations.fields.utn} />}
                      readOnly={values.inputMethod === InputMethod.Found}
                      disabled={submitting}
                    />

                    <HelpButton
                      className="absolute top-4 right-4 -my-[2px]"
                      onClick={() => ticketBarcodeModal.open(() => null, undefined)}
                    />
                  </div>
                ) : (
                  ticket.format === TicketFormat.PAPER && (
                    <React.Fragment>
                      <div className="relative">
                        <ValidatedField
                          field={InputWithLabel}
                          as={MaskedInput}
                          mask="00000"
                          id="number"
                          name="number"
                          type="text"
                          label={<FormattedMessage id={translations.fields.ticketNumber} />}
                          readOnly={values.inputMethod === InputMethod.Found}
                          disabled={submitting}
                        />

                        <HelpButton
                          className="absolute top-4 right-4 -my-[2px]"
                          onClick={() => ticketNumberModal.open(() => null, undefined)}
                        />
                      </div>

                      <div className="relative">
                        <ValidatedField
                          field={InputWithLabel}
                          as={MaskedInput}
                          mask="0000-0000"
                          id="reference"
                          name="reference"
                          type="text"
                          label={<FormattedMessage id={translations.fields.ticketReference} />}
                          readOnly={values.inputMethod === InputMethod.Found}
                          disabled={submitting}
                          parse={(value) => value?.replace('-', '')}
                        />
                        <HelpButton
                          className="absolute top-4 right-4 -my-[2px]"
                          onClick={() => ticketReferenceModal.open(() => null, undefined)}
                        />
                      </div>
                    </React.Fragment>
                  )
                )}

                {values.inputMethod !== InputMethod.Search && (
                  <React.Fragment>
                    <ValidatedField
                      field={InputWithLabel}
                      as={Select}
                      id="purchase-station"
                      name="purchaseStationId"
                      type="text"
                      label={<FormattedMessage id={translations.fields.purchaseStation} />}
                      readOnly={values.inputMethod === InputMethod.Found && !!initialValues.purchaseStationId}
                      disabled={submitting}
                      items={sellingStations.map((s) => ({ value: s.id, name: s.name }))}
                      searchable
                      className="col-start-1"
                    />

                    <ValidatedField
                      field={InputWithLabel}
                      as={Select}
                      id="purchase-method"
                      name="purchaseMethod"
                      type="text"
                      label={<FormattedMessage id={translations.fields.purchaseMethod} />}
                      readOnly={values.inputMethod === InputMethod.Found && !!initialValues.purchaseMethod}
                      disabled={submitting}
                      items={Object.values(PurchaseMethod).map((i) => ({
                        value: i,
                        name: intl.formatMessage({ id: translations.enum.purchaseMethod[i] })
                      }))}
                      searchable
                    />

                    <ValidatedField
                      field={InputWithLabel}
                      as={Select}
                      id="origin-station"
                      name="originStationId"
                      type="text"
                      label={<FormattedMessage id={translations.fields.origin} />}
                      readOnly={values.inputMethod === InputMethod.Found && !!initialValues.originStationId}
                      disabled={submitting}
                      items={stations.map((s) => ({ value: s.id, name: s.name }))}
                      searchable
                    />

                    <ValidatedField
                      field={InputWithLabel}
                      as={Select}
                      id="destination-station"
                      name="destinationStationId"
                      type="text"
                      label={<FormattedMessage id={translations.fields.destination} />}
                      readOnly={values.inputMethod === InputMethod.Found && !!initialValues.destinationStationId}
                      items={stations.map((s) => ({ value: s.id, name: s.name }))}
                      searchable
                    />

                    <ValidatedField
                      field={InputWithLabel}
                      as={DateInput}
                      id="valid-from"
                      name="validFrom"
                      mask={DATE_FORMAT}
                      type="text"
                      label={
                        <>
                          <FormattedMessage id={translations.fields.validFrom} />
                          <span className="ml-1 text-xs lowercase">({DATE_FORMAT})</span>
                        </>
                      }
                      readOnly={values.inputMethod === InputMethod.Found && !!initialValues.validFrom}
                      disabled={submitting}
                    />

                    <ValidatedField
                      field={InputWithLabel}
                      as={DateInput}
                      id="valid-to"
                      name="validTo"
                      mask={DATE_FORMAT}
                      type="text"
                      label={
                        <>
                          <FormattedMessage id={translations.fields.validTo} />
                          <span className="ml-1 text-xs lowercase">({DATE_FORMAT})</span>
                        </>
                      }
                      readOnly={values.inputMethod === InputMethod.Found && !!initialValues.validTo}
                      disabled={submitting}
                    />

                    <ValidatedField
                      field={InputWithLabel}
                      as={CurrencyInput}
                      id="ticket-value"
                      name="value"
                      type="text"
                      label={<FormattedMessage id={translations.fields.value} />}
                      readOnly={values.inputMethod === InputMethod.Found && !!initialValues.value}
                      disabled={submitting}
                      currency="£"
                    />

                    <ValidatedField
                      field={InputWithLabel}
                      as={Select}
                      id="class"
                      name="class"
                      type="text"
                      label={<FormattedMessage id={translations.fields.ticketClass} />}
                      readOnly={values.inputMethod === InputMethod.Found && !!initialValues.class}
                      disabled={submitting}
                      items={[
                        {
                          value: TicketClass.FIRST,
                          name: intl.formatMessage({ id: translations.enum.ticketClass.firstClass })
                        },
                        {
                          value: TicketClass.SECOND,
                          name: intl.formatMessage({ id: translations.enum.ticketClass.standard })
                        }
                      ]}
                    />

                    <ValidatedField
                      field={InputWithLabel}
                      as={DateInput}
                      id="dateOfTravel"
                      name="dateOfTravel"
                      mask={DATE_FORMAT}
                      type="text"
                      label={
                        <>
                          <FormattedMessage id={translations.fields.dateOfTravel} />
                          <span className="ml-1 text-xs lowercase">({DATE_FORMAT})</span>
                        </>
                      }
                      readOnly={values.inputMethod === InputMethod.Found && !!initialValues.dateOfTravel}
                      disabled={submitting}
                    />
                  </React.Fragment>
                )}
              </div>

              {!ticket.railcardId &&
                railcards.length > 0 &&
                [InputMethod.Manual, InputMethod.NotFound].includes(values.inputMethod) && (
                  <div className="grid max-w-4xl gap-4 md:grid-cols-2">
                    <div className="space-y-1">
                      <label className="font-bold">
                        <FormattedMessage id={translations.misc.railcard} />
                      </label>

                      <ValidatedField
                        field={Input}
                        as={Select}
                        id="railcard-id"
                        name="railcardId"
                        type="text"
                        disabled={submitting}
                        items={railcards.map((railcard) => ({
                          value: railcard.id,
                          name: railcard.name
                        }))}
                      />
                    </div>
                  </div>
                )}
            </div>

            <StepFooter>
              <div>
                <StepFooterButtons>
                  <SubmitButton loading={submitting} className="md:order-10">
                    {values.inputMethod === InputMethod.Search ? (
                      <FormattedMessage id={translations.pages.progress.ticketDetails.findTicket} />
                    ) : (
                      <FormattedMessage id={translations.pages.progress.ticketDetails.next} />
                    )}
                  </SubmitButton>

                  {values.inputMethod === InputMethod.Search && ticket.format !== TicketFormat.BARCODE && (
                    <Button
                      as="button"
                      type="button"
                      appearance="secondary"
                      onClick={() => form.change('inputMethod', InputMethod.Manual)}
                      className="w-full"
                    >
                      <FormattedMessage id={translations.pages.progress.ticketDetails.enterManually} />
                    </Button>
                  )}
                </StepFooterButtons>
              </div>

              <BackButton onClick={() => onBack(values, form)} />
            </StepFooter>
          </React.Fragment>
        )}
      </Form>

      <Modal {...ticketNumberModal.props} />
      <Modal {...ticketReferenceModal.props} />
      <Modal {...ticketBarcodeModal.props} />
    </Step>
  );
};
