import * as React from 'react';

import { Input } from './Input';

export interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  value?: string;
  length: number;
  onChange(value: string): void;
}

export const onlyNumber = (value: string) => value.replace(/[^0-9]/g, '');

export const OtpInput: React.FC<Props> = ({ value, length, onChange, readOnly, disabled }) => {
  const refs = React.useMemo(() => Array.from({ length }).map(() => React.createRef<HTMLInputElement>()), [length]);

  const inputs = React.useMemo(
    () =>
      refs.map((_, index) => {
        const normalizedValue = onlyNumber(value ?? '');

        return {
          ref: refs[index],
          value: normalizedValue.substring(index, index + 1),
          onPaste: (event: React.ClipboardEvent<HTMLInputElement>) => {
            event.preventDefault();
            const pasteValue = event.clipboardData.getData('text').substring(0, length);

            return onChange(onlyNumber(pasteValue));
          },
          onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key !== 'Backspace') return;
            if ((event.target as HTMLInputElement).value?.length > 0) return;

            refs[index - 1]?.current?.select();
            event.preventDefault();
          },
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = onlyNumber(event.target.value);

            const start = normalizedValue.substring(0, index) ?? '';
            const end = normalizedValue.substring(index + 1, normalizedValue.length);

            if (value.length > 0) refs[index + 1]?.current?.select();

            return onChange(start + value + end);
          }
        };
      }),
    [refs, value, onChange, length]
  );

  return (
    <div className="-m-2 flex items-center">
      {inputs.map(({ ref, value, onChange, onPaste, onKeyDown }, index) => (
        <Input
          key={index}
          placeholder="-"
          type="tel"
          minLength={1}
          maxLength={1}
          className="m-4 h-10 w-10 text-center md:h-16 md:w-16"
          {...{ ref, value, onChange, onPaste, onKeyDown, readOnly, disabled }}
        />
      ))}
    </div>
  );
};
