import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

import { ProgressManager } from '@/hooks/useProgressManager';
import { translations } from '@/locales';
import { apiClient } from '@/services/api-client';

import { Alert } from '@/components/Alert';
import { BackButton } from '@/components/BackButton';
import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Step, StepFooter, StepHeader } from '@/components/Step';
import { SubmitButton } from '@/components/SubmitButton';

const schema = yup
  .object({
    reference: yup.string().required().label(translations.fields.bookingReference),
    email: yup.string().email().required().label(translations.fields.emailAddress)
  })
  .required();

export const SearchTicketsByBookingStep: React.FC = () => {
  const { back, state, updateBooking } = ProgressManager.useContainer();

  const onSubmit = React.useCallback(
    async ({ reference, email }: yup.InferType<typeof schema>) =>
      await apiClient.booking.getBooking({ reference, email }).then(updateBooking),
    [updateBooking]
  );

  return (
    <Step>
      <StepHeader title={<FormattedMessage id={translations.pages.progress.searchTicketsByBooking.title} />} />

      <Form
        {...{
          initialValues: { reference: state.booking?.reference, email: state.booking?.customer?.email },
          schema,
          onSubmit
        }}
      >
        {({ submitting, submitError }) => (
          <React.Fragment>
            <div className="space-y-4">
              <Alert type="info" title="Test instructions:">
                <div>Booking reference: 2217220</div>
                <div>
                  Email: <span className="font-medium">George.robbins@gtrailway.com</span>
                </div>
              </Alert>

              {!!submitError && <Alert type="error" title="We didn't find your ticket"></Alert>}

              <div className="grid gap-4 md:grid-cols-2">
                <ValidatedField
                  field={InputWithLabel}
                  id="reference"
                  name="reference"
                  type="text"
                  label={<FormattedMessage id={translations.fields.bookingReference} />}
                  disabled={submitting}
                />

                <ValidatedField
                  field={InputWithLabel}
                  id="email"
                  name="email"
                  type="text"
                  label={<FormattedMessage id={translations.fields.emailAddress} />}
                  disabled={submitting}
                />
              </div>
            </div>

            <StepFooter>
              <SubmitButton loading={submitting}>
                <FormattedMessage id={translations.buttons.continue} />
              </SubmitButton>

              <BackButton onClick={() => back()} />
            </StepFooter>
          </React.Fragment>
        )}
      </Form>
    </Step>
  );
};
