import React from 'react';
import { Navigate, Route, Routes } from 'react-router';

import { ProgressPage } from '@/pages/ProgressPage';
import { RefundCompletedPage } from '@/pages/RefundCompletedPage';
import { RefundStatusPage } from '@/pages/RefundStatusPage';
import { RefundSentPage } from '@/pages/RefundStatusPage/RefundSentPage';
import { RefundUpdatePage } from './pages/RefundUpdatePage';

export const App: React.FC = () => (
  <Routes>
    <Route path="/" element={<ProgressPage />} />

    <Route path="/completed" element={<RefundCompletedPage />} />

    <Route path="/sent" element={<RefundSentPage />} />

    <Route path="/status/:hash/:claimRef" element={<RefundStatusPage />} />

    <Route path="/update/:type/:hash/:claimRef" element={<RefundUpdatePage />} />

    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
);
