import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { translations } from '@/locales';

export const HelpButton: React.FC<React.HTMLAttributes<HTMLButtonElement>> = (props) => (
  <button
    {...props}
    type="button"
    className={classnames(
      'flex items-center justify-center w-5 h-5 p-2 rounded-full bg-[#003F2E] text-white text-sm cursor-pointer select-none',
      props.className
    )}
  >
    <span className="sr-only">
      <FormattedMessage id={translations.misc.help} />
    </span>
    <span aria-hidden="true">?</span>
  </button>
);
