import React from 'react';

import { InstanceProps, OpenModal } from '@/components/Modal';

export const useModal = <P extends InstanceProps<any[], any>>(modal: React.ComponentType<P>) => {
  const [onAction, setOnAction] = React.useState<P['onAction']>();
  const [data, setData] = React.useState<P['data']>({} as P['data']);

  return React.useMemo(
    () => ({
      props: {
        isOpen: !!onAction,
        modal,
        data,
        onAction: ((...args) => {
          onAction?.(...args);
          setOnAction(undefined);
        }) as P['onAction'],
        onClose: () => setOnAction(undefined)
      },
      open: ((onAction, data) => {
        setData(data);
        return setOnAction(() => onAction);
      }) as OpenModal<P>
    }),
    [modal, onAction, setOnAction, data, setData]
  );
};
