import useSWR from 'swr';

import { apiClient } from '@/services/api-client';

export const useWarrants = () => {
  return useSWR({ key: `getWarrants` }, () => apiClient.warrant.getWarrantAccounts(), {
    revalidateOnMount: true,
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });
};
